import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ion-padding-horizontal ion-padding-top" }
const _hoisted_2 = { class: "ion-padding" }
const _hoisted_3 = { class: "c-form" }
const _hoisted_4 = { class: "c-form-group" }
const _hoisted_5 = { class: "c-form-group" }
const _hoisted_6 = { class: "c-form-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_header = _resolveComponent("app-header")!
  const _component_c_card_header = _resolveComponent("c-card-header")!
  const _component_c_image_bg = _resolveComponent("c-image-bg")!
  const _component_services_popular_card = _resolveComponent("services-popular-card")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_c_content_loading = _resolveComponent("c-content-loading")!
  const _component_tablet_side_layout = _resolveComponent("tablet-side-layout")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_app_footer = _resolveComponent("app-footer")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_app_header, {
        title: _ctx.$t('views.user_edit.title')
      }, null, 8, ["title"]),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_tablet_side_layout, null, {
            side: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_c_card_header, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.carId), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_c_image_bg, {
                  class: "ion-margin-bottom",
                  ratio: 0.6,
                  src: _ctx.carCover
                }, null, 8, ["ratio", "src"]),
                (_ctx.isTabletAndUp)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _withDirectives(_createVNode(_component_c_card_header, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('views.user_edit.popular_services_card_title')), 1)
                        ]),
                        _: 1
                      }, 512), [
                        [_vShow, _ctx.carId]
                      ]),
                      _createVNode(_component_services_popular_card, { "car-id": _ctx.carId }, null, 8, ["car-id"])
                    ], 64))
                  : _createCommentVNode("", true)
              ])
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_c_card_header, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('views.user_edit.form_title')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_c_content_loading, { action: _ctx.loadCar }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("div", _hoisted_4, [
                        _createVNode(_component_ion_input, {
                          class: "core-input",
                          placeholder: _ctx.$t('views.user_edit.input_client_name'),
                          autocapitalize: "words",
                          modelValue: _ctx.car.info.ownerName,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.car.info.ownerName) = $event))
                        }, null, 8, ["placeholder", "modelValue"])
                      ]),
                      _createElementVNode("div", _hoisted_5, [
                        _createVNode(_component_ion_input, {
                          class: "core-input",
                          placeholder: _ctx.$t('views.user_edit.input_client_phone'),
                          modelValue: _ctx.car.info.ownerPhone,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.car.info.ownerPhone) = $event)),
                          type: "tel",
                          inputmode: "tel"
                        }, null, 8, ["placeholder", "modelValue"])
                      ]),
                      _createElementVNode("div", _hoisted_6, [
                        _createVNode(_component_ion_textarea, {
                          class: "core-input",
                          placeholder: _ctx.$t('views.user_edit.input_client_comment'),
                          autocapitalize: "sentences",
                          rows: 3,
                          modelValue: _ctx.car.info.comment,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.car.info.comment) = $event))
                        }, null, 8, ["placeholder", "modelValue"])
                      ])
                    ])
                  ]),
                  _: 1
                }, 8, ["action"])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_fab, {
            vertical: "bottom",
            horizontal: "end",
            slot: "fixed"
          }, {
            default: _withCtx(() => [
              _withDirectives(_createVNode(_component_ion_button, {
                disabled: _ctx.car.loadingSave,
                color: "primary",
                shape: "round",
                onClick: _ctx.saveAction
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.saveBtnText), 1)
                ]),
                _: 1
              }, 8, ["disabled", "onClick"]), [
                [_vShow, !_ctx.car.loadingInfo]
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_app_footer)
    ]),
    _: 1
  }))
}