
import { computed, defineComponent, PropType, Ref } from 'vue';
import { ProgressInformation } from '@/composables/progress';

export type GetProgressContext = () => Ref<ProgressInformation>;

export default defineComponent({
  props: {
    getContext: {
      type: Function as PropType<GetProgressContext>,
      required: true
    },
    color: {
      type: String,
      required: false,
    }
  },

  setup(props) {
    // Может быть реактивным
    const ctx = props.getContext();
    const progressCoof = computed(() => ctx.value.progress / 100);

    return {
      ctx,
      progressCoof,
    };
  }
});
