
import { computed, defineComponent, reactive, ref } from 'vue'
import { useApp } from '@/composables/useApp'
import { useRoute, useRouter } from 'vue-router'
import { useToast } from '@/composables/toast'
import InputPos from '@/components/pos/InputPos.vue'
import { useI18n } from 'vue-i18n';

export default defineComponent({
  components: { InputPos },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();
    const { repositories } = useApp();
    const { t } = useI18n();

    const isInAction = (route.params.action === 'in');
    const processing = ref(false);
    const form = reactive({
      sum: '0',
      comment: '',
    });

    const buttonConfirmDisabled = computed(() => {
      return processing.value || !(+form.sum);
    });

    const buttonConfirmText = computed(() => {
      return (processing.value)
        ? t('views.pos_cash_in_or_out.btn_processing')
        : t('views.pos_cash_in_or_out.btn_confirm');
    });

    async function confirmAction() {
      try {
        if (isInAction) {
          await repositories.pos.cashIn(+form.sum, form.comment || undefined);
        } else {
          await repositories.pos.cashOut(+form.sum, form.comment || undefined);
        }
        
        toast.success(t('views.pos_cash_in_or_out.message_success_operation'));
        router.back();
      } catch (e) {
        toast.error(e, 4000, {
          defaultHeader: t('views.pos_cash_in_or_out.header_error_operation')
        });
      }
    }

    const pageTitle = computed(() => {
      return isInAction
        ? t('views.pos_cash_in_or_out.title_in')
        : t('views.pos_cash_in_or_out.title_out');
    });

    return {
      isInAction,
      buttonConfirmDisabled,
      buttonConfirmText,
      confirmAction,
      form,
      pageTitle,
    };
  }
});
