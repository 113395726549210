
import { defineComponent, reactive, ref, watch } from 'vue';
import AppHeaderNewVisit from '@/components/layout/AppHeaderNewVisit.vue';
import { useStore } from '@/composables/useApp';
import { useRoute, useRouter } from 'vue-router';
import { get, pick } from 'lodash';
import { CarBodyRequest } from '@/repositories/Models/Car';
import { isClientError } from '@/repositories';
import ServicesPopularCard from '@/components/service/ServicesPopularCard.vue';
import GroupPartnerInputCard from '@/components/group-partner/GroupPartnerInputCard.vue';
import { useNewVisitPhoto } from '@/composables/visit';
import { isTabletAndUp } from '@/helpers/adaptive';
import { useCreateVisitStep } from '@/composables/visit';
import { useMetrica } from '@/composables/useMetrica';
import { useI18n } from 'vue-i18n';
import { preparePhoneCore12LegacyFormat } from '@/utils/string';
import { useToast } from '@/composables/toast';

export default defineComponent({
  components: {
    AppHeaderNewVisit,
    ServicesPopularCard,
    GroupPartnerInputCard,
  },

  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { emitEvent } = useMetrica();
    const toast = useToast();
    const { t } = useI18n();
    const { isPreentry, visitState, visitType } = useCreateVisitStep();
    const { visitImageCover } = useNewVisitPhoto({ visitState, onlyMeta: isPreentry });

    const carId = get(route.params, 'carId', '') as string;
    const groupPartnerId = ref<number|string>(get(visitState.body, 'group.id', ''));
    watch(groupPartnerId, (groupId, prevGroupId) => {
      const action = (groupId ? (prevGroupId ? 'replace' : 'select') : 'unselect');
      emitEvent(`visit/${visitType}/client/partner/${action}`);
    });

    // Означает что страница открыта повторно для редактирования
    // (можно открыть в последующих шагах)
    const isActEdit = route.query.act === 'edit';

    const car = reactive({
      loadingInfo: false,
      loadingSave: false,
      fromСacheInfo: false,
      info: {
        // Специально не указывать, станет доступен после
        // загрузки информации об автомобиле с сервера
        // id: carId,

        ownerName: '',
        ownerPhone: '',
        comment: '',
      } as CarBodyRequest,
    });

    /** Загрузка информации об автомобиле и владельце */
    async function loadCar() {
      car.loadingInfo = true;

      try {
        const data = await store.car.getCar(carId);
        car.fromСacheInfo = data.fromСache;

        if (!groupPartnerId.value && !isActEdit) {
          groupPartnerId.value = get(data.data, 'group.id', '');
        }

        Object.assign(car.info, pick<CarBodyRequest>(data.data, [
          'id', 'comment',
          'ownerName', 'ownerPhone',
          'number',
        ]));
      } catch(e) {
        if (isClientError(e) && e.response?.status === 404) {
          toast.error(t('views.visit_new_client.error_load_car_not_exist'), 4000, {
            defaultHeader: t('views.visit_new_client.header_error_car_not_exists')
          });
        }

        throw e;
      } finally {
        car.loadingInfo = false;
      }
    }

    /** Обновление информации об автомобиле */
    async function updateCar() {
      car.loadingSave = true;

      try {
        if (!car.info.id) {
          throw new Error('No vehicle found to update');
        }

        await store.car.update(car.info.id, {
          ...car.info,
          ownerPhone: preparePhoneCore12LegacyFormat(car.info.ownerPhone),
        });

        // const updateInfo = ...
        // if (updateInfo.sendingDelayed) {
        //   toast.warning('Интернет недоступен, данные сохранены, и синхрнизируются после пояления интернета');
        // }
      } catch (e) {
        toast.error(e, 4000, {
          defaultHeader: t('views.visit_new_client.header_error_update')
        });

        throw e;
      } finally {
        car.loadingSave = false;
      }
    }

    async function next() {
      await updateCar();

      // fix: Чтобы правильно перекэшировать информацию
      await store.car.getCar(carId);

      visitState.body.car = {
        id: carId,
        number: car.info.number,
      };

      const oldGroupId = visitState.body.group?.id;

      visitState.body.group = groupPartnerId.value
        ? { id: +groupPartnerId.value } : undefined;

      if (isActEdit) {
        // Если при редактировании информации был изменен конрагент,
        // то стоимость услуг могла измениться, и чтобы оформить заказ
        // с новыми ценами, ранее выбранные услуги необходимо сбросить
        if (groupPartnerId.value != oldGroupId) {
          visitState.body.providedServices = [];
        }

        router.back();
        return;
      }

      emitEvent(`visit/${visitType}/client/next`);

      router.push({
        // В предварительной записи страницу выбора бокса пропускаем,
        // т.к. бокс быдет выбран на странице календаря
        name: isPreentry ? 'preentry-service-type' : 'visit-new-box',
        params: { visitId: 0 },
      });
    }

    return {
      carId,
      car,
      visitImageCover,
      next,
      groupPartnerId,
      isActEdit,
      loadCar,
      isTabletAndUp,
      isPreentry,
    };
  },
});
