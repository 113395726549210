
import { computed, defineComponent, ref } from 'vue'
import { useRoute } from 'vue-router'
import { toString } from 'lodash'
import { useStore } from '@/composables/useApp';
import { CarVisitCollectionItem } from '@/repositories/Models/CarVisit'
import VisitsArchiveGroupsByDate from '@/components/visit/VisitsArchiveGroupsByDate.vue'
import ServicesPopularCard from '@/components/service/ServicesPopularCard.vue'
import { useI18n } from 'vue-i18n';

export default defineComponent({
  components: {
    VisitsArchiveGroupsByDate,
    ServicesPopularCard,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const { t } = useI18n();

    const carId = toString(route.params.carId);

    // carId - по сути номер автомобиля, поэтому его можно отображать в заголовке
    const pageTitle = computed(() => t('views.visits_car_history.title', { carNumber: carId }));

    const visits = ref<CarVisitCollectionItem[]>([]);
    async function loadVisitsHistory() {
      const { data } = await store.visit.getCarVisitCollection({
        limit: 80,
        car: carId,
      });

      visits.value = data.items;
    }

    return {
      visits,
      pageTitle,
      loadVisitsHistory,
      carId,
    };
  }
});
