
import { defineComponent, onMounted, PropType, ref } from 'vue'
import { BaseCollectionResponse, BaseQueryPage } from '@/repositories/Models/Base'
import { errorToMessageString, UNKNOW_ERROR_MESAGE } from '@/helpers/error'
import { isOnline } from '@/helpers/network'
import { isClientError } from '@/repositories'

export default defineComponent({
  props: {
    infinite: {
      type: Function as PropType<(params: BaseQueryPage) => Promise<BaseCollectionResponse<any>>>,
      required: true,
    },
    perPage: {
      type: Number,
      default: 30,
    },
    loadingText: {
      type: String,
    },
    threshold: {
      type: [String, Number],
      default: '200px'
    }
  },
  setup(props) {
    const isDisabled = ref(false);
    const ionInfiniteScrollRef = ref<any>(null);

    const loading = ref(false);
    const items = ref<any[]>([]);
    const total = ref(0);
    const errorMessage = ref<any>(null);
    const currentPage = ref(0);

    async function loadAction() {
      loading.value = true;

      try {
        const qp = props.infinite.call(null, {
          page: currentPage.value + 1,
          limit: props.perPage
        });

        if (!(qp instanceof Promise)) {
          throw new Error('Функция infinite для CInfiniteLoading должна быть асинхронной!');
        }

        const res = await qp;

        if (res.page === 1) {
          items.value = [];
        }

        items.value.push(...res.items);
        currentPage.value = res.page;
        total.value = res.total;
        isDisabled.value = res.page >= res.pages;
      } catch (e) {
        const data = isClientError(e)
          ? e.response?.data
          : e;

        if (!data) {
          errorMessage.value = isOnline()
            ? UNKNOW_ERROR_MESAGE
            : 'Возникла ошибка сети. <br> Проверьте подключение к интрнету и повторите попытку.';
        } else {
          errorMessage.value = errorToMessageString(data);
        }
      } finally {
        ionInfiniteScrollRef.value?.$el?.complete();
        loading.value = false;
      }
    }

    function reload() {
      loadAction();
    }

    async function resetAndLoad() {
      items.value = [];
      currentPage.value = 0;
      total.value = 0;
      errorMessage.value = null;
      isDisabled.value = false;

      return await loadAction();
    }

    onMounted(loadAction);

    return {
      loadAction,
      isDisabled,
      ionInfiniteScrollRef,
      loading,
      items,
      currentPage,
      reload,
      errorMessage,
      resetAndLoad,
      total,
    };
  }
});
