
import { useStore } from '@/composables/useApp';
import { defineComponent, DeepReadonly, ref, PropType, computed, ComputedRef } from 'vue';
import { ThisClientPointTypeItem } from '@/repositories/Models/Point';
import { VisitTypeAndCategory } from '@/helpers/visit';
import { keyBy, pick } from 'lodash';
import { useModalNav } from './modal';
import SelectCategoryView from './SelectCategoryView.vue';
import { useMetrica } from '@/composables/useMetrica';
// import { useIonNav } from '@/composables/useNavManager';

export default defineComponent({
  inheritAttrs: false,
  props: {
    selectedTypeAndCategoriesCRef: {
      type: Object as PropType<ComputedRef<VisitTypeAndCategory[]>>,
      default: () => computed(() => [] as VisitTypeAndCategory[]),
    },
    userCloseModal: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const store = useStore();
    const { emitEvent } = useMetrica();

    const pointTypeSelectedList = computed(() => keyBy(props.selectedTypeAndCategoriesCRef.value, 'typeId'));
    const pointTypeSelected = ref<DeepReadonly<ThisClientPointTypeItem>|null>(null);
    const pointTypes = ref<DeepReadonly<ThisClientPointTypeItem[]>>([]);
    async function loadPointTypes() {
      const thisClientInfo = await store.point.getThisClient();
      pointTypes.value = thisClientInfo.pointTypes;
    }

    const nav = useModalNav();
    function selectPointType(pointType: DeepReadonly<ThisClientPointTypeItem> /*, event: Event */) {
      emitEvent('visit/edit/category-type', pick(pointType, ['id', 'name', 'style']));

      pointTypeSelected.value = pointType;
      nav.pushView(SelectCategoryView, { typeId: pointType.id });

      // const nav = useIonNav(event.target as HTMLElement);
      // nav?.push(
      //   SelectCategoryView,
      //   { typeId: pointType.id },
      //   { animated: false }
      // );
    }

    return {
      pointTypes,
      loadPointTypes,
      selectPointType,
      pointTypeSelectedList,
    };
  }
});
