import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "c-car-side-info pb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_c_card_header = _resolveComponent("c-card-header")!
  const _component_c_image_bg = _resolveComponent("c-image-bg")!
  const _component_client_info_card = _resolveComponent("client-info-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_c_card_header, null, {
      default: _withCtx(() => [
        (!_ctx.currentCar)
          ? (_openBlock(), _createBlock(_component_ion_skeleton_text, {
              key: 0,
              animated: "",
              style: {
        width: '140px',
        height: '1em',
      }
            }))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createTextVNode(_toDisplayString(_ctx.currentCar.number), 1)
            ], 64))
      ]),
      _: 1
    }),
    _createVNode(_component_c_image_bg, {
      ratio: 0.6,
      src: _ctx.carImageCover
    }, null, 8, ["ratio", "src"]),
    _createVNode(_component_c_card_header, { class: "ion-hide-md-down ion-margin-top" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('components.client_side_info.client_card_info_title')), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_client_info_card, {
      class: "ion-hide-md-down",
      "car-id": _ctx.carId,
      onCarLoaded: _ctx.setCurrentCar
    }, null, 8, ["car-id", "onCarLoaded"])
  ]))
}