

import { defineComponent, PropType, ref, computed, watch, inject, UnwrapRef, toRef, onMounted } from 'vue';
import { debounce, get, isEmpty, pick } from 'lodash';
import { modalController } from '@ionic/vue';
import ServiceProvideItemCostModal from './ServiceProvideItemCostModal.vue';
import { getServiceRangePrice } from '@/utils/price';
import { ServiceForForm } from '@/store/ServiceStore';
import { useMetrica } from '@/composables/useMetrica';
import { useI18n } from 'vue-i18n';
import { useCurrency } from '@/composables/currency';
import { NewVisitSatate } from '@/store/NewVisitState';
import { ProvideServiceItemPart } from '@/composables/provideServices';
import { useDiscountServiceItem } from '@/composables/useDiscounts';

export default defineComponent({
  props: {
    provided: null,
    service: {
      type: Object as PropType<ServiceForForm>,
      required: true
    },
  },
  setup(props, { emit }) {
    const { emitEvent } = useMetrica();
    const { t } = useI18n();
    const { formatCurrency } = useCurrency();
    const visitState = inject<UnwrapRef<NewVisitSatate>>('visitState');

    //#region Price Modify
    // TODO: Разобраться зачем массив из нескольких цен
    const priceOptions = getServiceRangePrice(props.service.prices[0], props.service.fluid);
    const priceItem = ref(priceOptions.minValue);
    const isPriceModify = computed(() => priceItem.value != priceOptions.minValue);

    const {
      discountPercent,
      discountGiftCampaign,
      discountViewBadges,
      applyDiscount,
      priceItemPercentDiscount
    } = useDiscountServiceItem({
      service: toRef(props, 'service'),
      visitState,
      priceItem,
    });

    function correctPrice(price: number): number {
      return Math.min(priceOptions.maxValue, Math.max(priceOptions.minValue, price));
    }

    async function openCostSettings() {
      if (true === costSettingsDisable.value) return;

      emitEvent('service/change/price/open', pick(props.service, ['id', 'name', 'repeatable']));

      const modal = await modalController.create({
        component: ServiceProvideItemCostModal,
        cssClass: 'core-modal-actions',
        swipeToClose: true,
        componentProps: {
          value: priceItem.value,
          service: props.service,
          setPrice(price: number) {
            priceItem.value = correctPrice(price);
          }
        },
      });

      await modal.present();
      return modal;
    }
    //#endregion Price Modify

    //#region Values
    const checked = ref(false);
    const quantity = ref(1);
    const emitEventChangeQuantityDebounce = debounce(() => {
      emitEvent('service/change/quantity', {
        quantity: quantity.value,
        ...pick(props.service, ['id', 'name']),
      });
    }, 2000);
    
    const priceCalcView = computed(() => {
      const priceItemFormatted = formatCurrency(priceItem.value);

      if (quantity.value <= 1) {
        return discountPercent.value
          ? priceItemFormatted
          : false;
      }

      return `${priceItemFormatted} X${quantity.value}`;
    });

    const priceTotal = computed(() => {
      let q = quantity.value;

      // Если применяется подарочная компания, то 1-н товар/услуга идет в подарок
      if (discountGiftCampaign.value) {
        --q;
      }

      return priceItemPercentDiscount.value * q;
    });

    const providedService = computed<ProvideServiceItemPart>(() => {
      const value: ProvideServiceItemPart = {
        service: { id: props.service.id },
        qty: quantity.value,
        singleItemPrice: priceItem.value,
        totalPrice: priceTotal.value,
        meta: {  },
      };

      // Применяем скидки
      // (статическая || накопительная)
      // && подарочная
      applyDiscount(value);

      return value;
    });
    
    /** Обновит основные параметры, не ломая логику значений */
    function mergeServiceValues(value: any) {
      let isChange = false;

      if (isEmpty(value)) {
        checked.value = false;
      }

      let newQuantity = get(value, 'qty') as number|null;
      if (newQuantity) {
        newQuantity = Number(newQuantity) || 0;
        newQuantity = Math.max(1, newQuantity);

        if (quantity.value !== newQuantity) {
          quantity.value = newQuantity;
          isChange = true;
        }
      } else if (quantity.value !== 1) {
        quantity.value = 1;
        isChange = true;
      }

      let newPriceItem = get(value, 'singleItemPrice') as number|null;
      if (newPriceItem) {
        newPriceItem = Number(newPriceItem) || 0;
        newPriceItem = correctPrice(newPriceItem);

        if (priceItem.value !== newPriceItem) {
          priceItem.value = newPriceItem;
          isChange = true;
        }
      } else if (priceItem.value !== priceOptions.minValue) {
        priceItem.value = priceOptions.minValue;
        isChange = true;
      }

      return isChange;
    }

    /** Обработает значение и вызовет событие изменения */
    function emitProvided() {
      let value = undefined;

      if (checked.value) {
        const providedValue = (props.provided && typeof props.provided === 'object') ? props.provided : {};

        // NOTE: Если для услуги имеется подарочная акция, другие не назначены и мы отмечаем элемент
        if (visitState && discountGiftCampaign.value) {
          visitState.giftCampaign[props.service.nomenclature.id] = discountGiftCampaign.value;
        }

        // Сливаем старое значение с новыми (чтобы дополнительные параметры не сбрасывались)
        value = {
          ...providedValue,
          ...providedService.value
        };
      } else {
        // NOTE: Если чекбокс сняли и подарочная акция активированна для данного подарка, то ее нужно удалить
        if (visitState && visitState.giftCampaign[props.service.nomenclature.id]) {
          delete visitState.giftCampaign[props.service.nomenclature.id];
        }
      }
      
      emit('update:provided', value);
    }

    // fix: Если убрать другой товар, на который ранее была установлена скидка, то акция автоматически не применится.
    // Для этого необходимо отслеживать состояние и применять ее в случае, если подарочная акция может быть автоматически применена.
    watch(() => visitState?.giftCampaign, () => {
      if (
        visitState
        && discountGiftCampaign.value
        && isEmpty(visitState.giftCampaign)
        && checked.value
      ) {
        visitState.giftCampaign[props.service.nomenclature.id] = discountGiftCampaign.value;
      }
    });

    if (props.provided) {
      checked.value = true;

      if (true == mergeServiceValues(props.provided)) {
        emitProvided();
      }
    }

    watch(() => props.provided, mergeServiceValues);
    watch(providedService, emitProvided);
    watch(checked, emitProvided);
    //#endregion

    const checkboxCol = ref<HTMLElement|null>(null);
    /** В случае клика по заголовку, инициируем событие в чекбоксе */
    async function onClickTitle() {
      if (checkboxCol.value) {
        const ionCheckbox = checkboxCol.value.querySelector('ion-checkbox');
        ionCheckbox?.dispatchEvent(new Event('click'));
      }
    }

    const costSettingsDisable = computed(() => !checked.value || !props.service.fluid);
    const costSettingsShow = computed(() => !props.service.isProduct);

    watch(quantity, emitEventChangeQuantityDebounce);

    onMounted(() => {
      if (props.provided && get(props.provided, 'meta.forceUpdate') === true) {
        mergeServiceValues(props.provided);
        emitProvided();
        console.log('--forceUpdate');
      }
    });

    return {
      onClickTitle,
      checkboxCol,
      openCostSettings,
      quantity,
      priceItem,
      priceTotal,
      checked,
      isPriceModify,
      costSettingsDisable,
      costSettingsShow,
      discountViewBadges,
      priceCalcView,
      t,
    };
  }
});
