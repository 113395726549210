
import { computed, defineComponent, ref, watch } from 'vue';
import AppHeaderNewVisit from '@/components/layout/AppHeaderNewVisit.vue';
import CInfiniteCalendar from '@/components/core/InfiniteCalendar/CInfiniteCalendar.vue';
import { usePreenryDatetimePage } from '@/composables/usePreentry';
import { useStore } from '@/composables/useApp';
import { asyncComputed } from '@/helpers/vue';
import { useCreateVisitStep, useVisitPreentryChangeDatetime } from '@/composables/visit';
import CModalStep, { StateValue } from '@/components/core/CModalStep.vue';
import CModalFloat from '@/components/core/CModalFloat.vue';
import { isTabletAndUp } from '@/helpers/adaptive';
import { useToast } from '@/composables/toast';
import { useRouter } from 'vue-router';
import { get } from 'lodash';
import { useI18n } from 'vue-i18n';
import VisitsPreentrySheduleBox, {
  PreentryValue,
  PointerInfo,
  PointerRect,
} from '@/components/visit/VisitsPreentrySheduleBox.vue';
import {
  formatDate,
  MINUTES_DAY_NUMBER,
  timeNumberToHM,
  dateToServerDatetimeString,
  parseToDate
} from '@/helpers/datetime';
import { useNavManager } from '@/composables/useNavManager';

export default defineComponent({
  components: {
    AppHeaderNewVisit,
    VisitsPreentrySheduleBox,
    CInfiniteCalendar,
    CModalStep,
    CModalFloat,
  },

  setup() {
    const store = useStore();
    const toast = useToast();
    const router = useRouter();
    const navManager = useNavManager();
    const { t } = useI18n();
    const {
      visitState,
      isPreentry,
      visitId,
      isChangePreentryDatetime,
      clearVisitState
    } = useCreateVisitStep();
    
    const {
      currentTab,
      customDate,
      customDateFormat,
      setCustomDate,
      clearCustomDate,
      viewSheduleDate,
      isSelectDate,
    } = usePreenryDatetimePage();

    const excludeVisitIds: string[] = [];
    const { updatePreentryDatetime: updatePreentryDatetimeRaw } = useVisitPreentryChangeDatetime();
    const updateLoading = ref(false);

    const nextButtonDisabled = computed(() => updateLoading.value);
    const nextButtonText = computed(() => {
      return (updateLoading.value)
        ? 'Подождите...'
        : t('views.visits_new_preentry_datetime.modal_confirm_btn')
      ;
    });

    // Если изменяем дату и время у существующего визита,
    // то нужно исключить его отображение из расписания.
    if (isChangePreentryDatetime && visitState.body.id) {
      excludeVisitIds.push(visitState.body.id);
    }

    async function update() {
      updateLoading.value = true;

      try {
        await updatePreentryDatetimeRaw();
        clearVisitState();
      } catch (e: any) {
        toast.error(e);
      } finally {
        updateLoading.value = false;
      }
    }

    function getSaveDate(): Date|null {
      const date = get(visitState.meta, 'saveDate', null)
      return (date instanceof Date) ? (new Date(date)) : null;
    }

    const normativeTimeMinutes = asyncComputed(async () => {
      return store.service.calcNormativesProvideService(
        visitState.body.providedServices || []
      );
    }, 0);

    /** @private */
    function getInitValue(): PreentryValue|null {
      if (visitState.body.creationDate && visitState.body.box) {
        const datetime = parseToDate(visitState.body.creationDate);

        setCustomDate(new Date(datetime));

        return {
          datetime,
          boxId: visitState.body.box.id,
        };
      }

      return null;
    }

    /** @private */
    function timeMinutesToText(minutes: number) {
      minutes = minutes % MINUTES_DAY_NUMBER;
      const [h, m] = timeNumberToHM(minutes);
      const mm = m > 9 ? `${m}` : `0${m}`;
      return `${h}:${mm}`;
    }

    const preentryValue = ref<PreentryValue|null>(getInitValue());
    const previewState = ref<StateValue>(preentryValue.value ? 'opened' : 'closed');
    const pointerInfo = ref<PointerInfo|null>(null);
    function setPointerInfo(info: PointerInfo|null) {
      pointerInfo.value = info;
      previewState.value = info ? 'preview' : 'closed';
    }

    if (!preentryValue.value) {
      // Отобразить заранее сохраненную дату
      setCustomDate(getSaveDate());
    }

    function onClickBackgroupPreviewModal() {
      if (previewState.value === 'opened') {
        previewState.value = 'preview';
      }
    }
    
    const BOX_REPLACE_PREVIEW_REGV = new RegExp(t('views.visits_new_preentry_datetime.modal_info_label_box_name') + ' ', 'i');
    const previewHeight = computed(() => isTabletAndUp.value ? 120 : 145);
    const previewInfo = computed(() => {
      let dateText = '—';
      let timeText = '—';
      let boxText = '—';
      let isBoxNumber = false;

      if (pointerInfo.value && preentryValue.value) {
        dateText = formatDate(preentryValue.value.datetime);
        timeText = timeMinutesToText(pointerInfo.value.time)
          + ' — '
          + timeMinutesToText(pointerInfo.value.time + normativeTimeMinutes.value);
        
        boxText = String(pointerInfo.value.box.name);
        isBoxNumber = BOX_REPLACE_PREVIEW_REGV.test(boxText)
        if (isBoxNumber) {
          boxText = boxText.replace(BOX_REPLACE_PREVIEW_REGV, '');
        }
      }

      const boxLabel = isBoxNumber
        ? t('views.visits_new_preentry_datetime.modal_info_label_box_number')
        : t('views.visits_new_preentry_datetime.modal_info_label_box_name');

      return [
        {
          label: t('views.visits_new_preentry_datetime.modal_info_label_time'),
          value: timeText
        },
        {
          label: t('views.visits_new_preentry_datetime.modal_info_label_date'),
          value: dateText
        },
        {
          label: boxLabel,
          value: boxText
        },
      ];
    });

    async function confirm() {
      if (!preentryValue.value) {
        toast.error(t('views.visits_new_preentry_datetime.error_empty_datetime'));
        return;
      }

      visitState.body.creationDate = dateToServerDatetimeString(preentryValue.value.datetime);
      visitState.body.box = { id: preentryValue.value.boxId };

      if (isChangePreentryDatetime) {
        await update();

        navManager.navigate({
          routerLink: { name: 'visits-preentry' },
          routerDirection: 'root',
        });
      }
      else {
        router.push({
          name: isPreentry ? 'preentry-doers' : 'visit-new-doers',
          params: { visitId },
        });
      }
    }

    // При выборе даты в календаре, лучше сбросить ранее выбранное значение
    watch(viewSheduleDate, () => {
      preentryValue.value = null;
    });

    const showTabletPreviewModal = computed(() => !!preentryValue.value && isTabletAndUp.value);
    const floatModalStyles = ref<Record<string, any>>({});

    function updatePointerRect(rect: PointerRect|null) {
      if (!rect) return;

      const edgeRight = rect.container.right - rect.right;
      const edgeLeft = rect.left - rect.container.left;
      const edgeBottom = rect.container.scrollHeight - rect.offsetTop - rect.height;

      const GAP = 10;

      let top = 'auto';
      let bottom = 'auto';
      let left = 'auto';
      let right = 'auto';
      
      let maxWidthNumber = 0;

      // Отобразить окно справа
      if (edgeRight >= edgeLeft || rect.offsetLeft < 200) {
        maxWidthNumber = edgeRight - GAP * 2;
        left = (rect.offsetLeft + rect.width + GAP) + 'px';
      }

      // Отобразить окно слева
      else {
        maxWidthNumber = edgeLeft - GAP * 2;
        right = (rect.container.width - rect.offsetLeft + GAP) + 'px';
      }

      // Выровнить по верхнему краю курсора
      if (edgeBottom > 400) {
        top = rect.offsetTop + 'px';
      }
      // Выровнить по нижнему краю курсора
      else {
        bottom = edgeBottom + 'px';
      }

      const maxWidth = Math.max(300, maxWidthNumber) + 'px';

      floatModalStyles.value = { top, left, right, bottom, maxWidth };
    }

    function onClickBackgroupFloatModal() {
      preentryValue.value = null;
    }

    function closeFloatModal() {
      preentryValue.value = null;
    }

    return {
      currentTab,
      viewSheduleDate,
      isSelectDate,
      customDate,
      customDateFormat,
      setCustomDate,
      clearCustomDate,
      normativeTimeMinutes,
      preentryValue,
      previewHeight,
      setPointerInfo,
      previewState,
      previewInfo,
      confirm,
      isTabletAndUp,
      onClickBackgroupPreviewModal,
      updatePointerRect,
      floatModalStyles,
      showTabletPreviewModal,
      onClickBackgroupFloatModal,
      closeFloatModal,
      excludeVisitIds,
      nextButtonDisabled,
      nextButtonText,
    };
  }
});
