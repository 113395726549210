
import { defineComponent } from 'vue'
import { networkStatusColor, networkStatusText } from '@/helpers/network'

export default defineComponent({
  setup() {
    return {
      networkStatusText,
      networkStatusColor,
    };
  }
});
