
import { defineComponent, ref } from 'vue'
import { useRoute } from 'vue-router'
import ServicesView from '@/components/service/price-view/ServicesView.vue'
import { useStore } from '@/composables/useApp'
import { scrollHide } from '@/directives/scrollHide'
import { debounce } from 'lodash';
import { useMetrica } from '@/composables/useMetrica'
import { useI18n } from 'vue-i18n';

export default defineComponent({
  components: { ServicesView },
  directives: { scrollHide },

  setup() {
    const route = useRoute();
    const store = useStore();
    const { emitEvent } = useMetrica();
    const { t } = useI18n();

    const catId = route.params.catId as string;

    const pageTitle = ref(t('views.pricelist_services.default_title'));
    const fixedContent = ref<HTMLDivElement|null>(null);

    // Чтобы прогрузить названия категорий
    store.point.getThisClient(); // async

    const inputSearchDebounce = debounce((text: string) => {
      if (text) {
        emitEvent('pricelist/search', { text });
      }
    }, 1400);

    return {
      catId,
      pageTitle,
      fixedContent,
      inputSearchDebounce,
    };
  }
});
