import { addIcons } from 'ionicons';
import { mapKeys, kebabCase } from 'lodash';

import {
  backspaceOutline,
  settingsOutline,
  addOutline,
  removeOutline,
  closeOutline,
  checkmarkOutline,
  alertCircleOutline,
  chevronBackOutline,
} from 'ionicons/icons';
import leftArrow from './left-arrow.svg';
import chatbox from './chatbox.svg';
import timer from './timer.svg';
import bag from './bag.svg';
import cash from './cash.svg';
import price from './price.svg';
import statistic from './statistic.svg';
import checkList from './check-list.svg';
import edit from './edit.svg';
import search from './search.svg';
import add from './add.svg';
import remove from './remove.svg';
import preferences from './preferences.svg';
import collapseArrow from './collapse-arrow.svg';
import calendar from './calendar.svg';
import visitsViewCard from './visits-view-card.svg';
import visitsViewList from './visits-view-list.svg';
import close from './close.svg';
import user from './user.svg';
import settings from './settings.svg';
import flashlight from './flashlight.svg';
import arrowRightCat from './arrow-right-cat.svg';
import camera from './camera.svg';

addIcons({
  //#region Ionic Icons
  ...mapKeys({
    backspaceOutline,
    settingsOutline,
    addOutline,
    removeOutline,
    closeOutline,
    checkmarkOutline,
    alertCircleOutline,
    chevronBackOutline,
  }, (value, key) => kebabCase(key)),
  //#endregion Ionic Icons

  //#region Core12 Icons
  ...mapKeys({
    leftArrow,
    chatbox,
    timer,
    bag,
    cash,
    price,
    statistic,
    checkList,
    edit,
    search,
    add,
    remove,
    preferences,
    collapseArrow,
    calendar,
    visitsViewCard,
    visitsViewList,
    close,
    user,
    settings,
    flashlight,
    arrowRightCat,
    camera,
  }, (value, key) => 'core-' + kebabCase(key)),
  //#endregion Core12 Icons
});