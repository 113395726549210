
import { computed, defineComponent, ref } from 'vue'
import carNoImage from '@/img/car-no-image.svg'
import { Car } from '@/repositories/Models/Car'
import ClientInfoCard from './ClientInfoCard.vue'

export default defineComponent({
  components: {
    ClientInfoCard,
  },
  props: {
    carId: {
      type: String,
      required: false,
    },
    carImage: {
      type: String,
      required: false,
    }
  },
  setup(props) {
    const carImageCover = computed(() => props.carImage || carNoImage);

    /**
     * Чтобы дополнительно не подгружать информацию о текущем автомобиле,
     * подождем пока она загрузится в специальном информационном боксе и сохраним ее
     */
    const currentCar = ref<Car|null>(null);
    function setCurrentCar(car: Car) {
      currentCar.value = car;
    }

    return {
      currentCar,
      carImageCover,
      setCurrentCar,
    };
  }
});
