
import { defineComponent, computed, provide, toRef } from 'vue'
import VisitsArchivePane from '@/components/visit/VisitsArchivePane.vue'
import VisitsArchiveSearch from '@/components/visit/VisitsArchiveSearch.vue'
import { trim } from 'lodash'

export default defineComponent({
  components: {
    VisitsArchivePane,
    VisitsArchiveSearch,
  },
  props: {
    search: {
      type: String,
      required: false,
    },
    viewType: {
      type: String,
      default: 'normal'
    }
  },
  setup(props) {
    const isSearchWords = computed(() => !!trim(props.search));
    provide('viewType', toRef(props, 'viewType'));

    return {
      isSearchWords,
    };
  },
});
