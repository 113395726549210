import { LocalizationDictionary } from '../types';

export default {
  locale: 'Русский',
  views: {
    //#region Visits
    // Страница заказов (главная)
    // url: /visits
    visits: {
      title: 'Заказы',
      tab_processing: 'В работе',
      tab_archive: 'В архиве',
      add_visit: 'Добавить заказ',
      search_input: 'Введите запрос',
    },

    // Карточка заказа
    // url: /visits/:id
    visit_single: {
      title: 'Карточка заказа',
      card_info_pay: 'Оплачено:',
      card_info_pay_points: 'Списано бонусов:',
      card_action_close_visit: 'Завершить обслуживание',
      card_action_return_work: 'Вернуть в работу',
      card_action_return_pay: 'Отменить платеж',
      card_action_invoice_fiscal: 'Фискальный чек',
      card_action_invoice: 'Распечатать пречек',
      card_action_cancel_preentry_visit: 'Отменить запись',
      card_action_сhange_datetime_preentry_visit: 'Изменить время записи',
      card_action_add_photo: 'Сделать фото автомобиля',
      action_pay: 'Принять оплату {price}',
      action_preentry_get_started: 'Начать выполнение заказа',
      action_preentry_apply_doers_and_get_started: 'Применить и начать выполнение',
      provide_services_title: 'Список услуг',
      provide_services_action_edit: 'Изменить детали заказа',
      client_card_title: 'Клиент',

      update_visit_info_backgroung: 'Обновление информации...',
      invoice_processing_wait: 'Обработка пречека...',
      invoice_fiscal_processing_wait: 'Отправка чека...',
      pay_warning_not_cashier: 'Для оплаты необходимо обладать правами кассира!',

      // Подтверждение действий предварительной записи
      alert_preentry_get_started_header: 'Подтвердите действие',
      alert_preentry_get_started_message: 'Приступить к выполнению заказа? После подтвержения статус заказа будет изменен на "В процессе"',
      alert_preentry_cancel_header: 'Подтвердите действие',
      alert_preentry_cancel_message: 'После отмены предварительной записи ее нельзя будет восстановить',
      
      // Подтверждение действий по заказу (завершить, вернуть в работу)
      alert_close_visit_header: 'Закрыть заказ',
      alert_close_visit_message: 'Подтвердите закрытие заказа',
      alert_reopen_visit_header: 'Возврат в работу',
      alert_reopen_visit_message: 'Подтвердите возврат заказа в работу',
      
      // Подтверждения действий с чеками (обычный, фискальный)
      alert_invoice_header: 'Пречек',
      alert_invoice_message: 'Подтвердите действие',
      alert_invoice_btn_confirm: 'Отправить',
      alert_invoice_fiscal_header: 'Фискальный чек',
      alert_invoice_fiscal_message: 'Подтвердите выставление фискального чека',
      alert_invoice_fiscal_btn_confirm: 'Подтвердить',

      // Заголовки ошибок закрытия и открытия
      header_error_finish_visit: 'Не удалось завершить выполнение',
      header_error_process_visit: 'Не удалось вернуть в работу',
    },

    // Оплата заказа и отмена платежа
    // url: /visits/:id/pay/:type/confirm
    // url: /visits/:id/pay/:type/return
    visit_pay: {
      title_accept: 'Оплата заказа',
      title_return: 'Возврат денег',

      label_total_accept: 'Сумма к оплате',
      label_total_return: 'Сумма к возврату',
      label_payment_type_accept: 'Способ оплаты',
      label_payment_type_return: 'Способ возврата',
      label_cash_deposited: 'Внесено наличных',
      label_pay_points: 'Оплата бонусами',
      label_odd_money: 'Сдача',

      alert_confirm_pay_header: 'Подтверждение оплаты',
      alert_confirm_pay_message: 'Подтвердите операцию оплаты',
      alert_confirm_return_pay_header: 'Возврат денег',
      alert_confirm_return_pay_message: 'Подтвердите операцию возврата денежных средств',
      
      cash_deposited_error: 'Вы внесли недостаточную сумму',
      pay_visit_success_message: 'Заказ в магазине успешно оплачен',

      confirm_btn_init: 'Инициализация...',
      confirm_btn_processing: 'Выполнение операции...',
      confirm_btn_confirm_return: 'Подтверить возврат',
      confirm_btn_confirm_pay: 'Подтвердить оплату',
      confirm_btn_confirm_pay_and_close: 'Подтвердить оплату и закрыть заказ',

      payment_type_card: 'Банковская карта',

      header_error_init: 'Ошибка инициализации кассы',
      header_error_pos: 'Ошибка кассы',
      header_error_pay_visit: 'Не удалось провести оплату',
      header_error_return_pay_visit: 'Не удалось отменить платеж',
    },

    // Распознование номера и фото (предварительная запись, новый визит)
    // url: /visits/new/photo-scanner
    // url: /visits/preentry/photo-scanner
    visit_new_photo: {
      title: 'Распознать номер',
      btn_skip_no_recognition: 'Продолжить без распознавания номера',
      btn_skip_no_photo: 'Продолжить оформление без фото',
      btn_number_recognition: 'Распознать номер',
      btn_create_photo: 'Сделать фото автомобиля',
      error_camera_no_init: 'Камера не инициализирована',
      error_create_photo: 'Не удалось сделать снимок',
      error_recognize_photo: 'Не удалось распознать номер',
      wait_recognize_number: 'Распознавание номера...',
    },

    // Ввод номера автомобиля
    // url: /visits/new/car-number
    // url: /visits/preentry/car-number
    visit_new_car_number: {
      title: 'Регистрация автомобиля',
      btn_outher_number_format: 'Другой формат номера',
      btn_next: 'Продолжить',
      modal_input_number_title: 'Введите номер',
      modal_input_number_btn_next: 'Подтвердить и продолжить',
      error_empty_car_number: 'Укажите номер автомобиля!',
      header_error_create_car: 'Автомобиль не создан',
    },

    // Ввод информации о клиенте
    // url: /visits/new/client/car/:number
    // url: /visits/preentry/client/car/:number
    visit_new_client: {
      title: 'О клиенте',
      popular_services_title: 'Популярные услуги',
      client_form_title: 'Информация о клиенте',
      client_input_name: 'Имя',
      client_input_phone: 'Телефон',
      client_input_comment: 'Комментарий',
      client_agrees_personal_data: 'Клиент согласен на обработку, хранение, распространение его персональных данных',
      btn_next: 'Продолжить',
      error_load_car_not_exist: 'По указанному номеру автомобиль не удалось найти, повторите процедуру регистрации номера',
      header_error_load_car_not_exists: 'Автомобиль не найден',
      header_error_update: 'Данные клиента не обновлены',
    },

    // Выбор бокса
    // url: /visits/new/0/box
    visit_new_box: {
      title: 'Выбор бокса',
      client_card_info_title: 'Клиент',
    },

    // Тип категории
    // url: /visits/new/0/type
    // url: /visits/preentry/0/type
    visit_new_services_type: {
      title: 'Тип услуги',
      client_card_info_title: 'Клиент',
    },

    // Выбор категории
    // url: /visits/new/0/type/:typeId/cat
    visit_new_service_category: {
      default_title: 'Категория',
      client_card_info_title: 'Клиент',
    },

    // Выбор товаров и услуг
    // url: /visits/new/0/services
    // url: /visits/preentry/0/services
    visit_new_services: {
      title: 'Список услуг',
      services_card_title: 'Выберите услугу',
      btn_next: 'Продолжить',
      alert_remove_cateory_header: 'Убрать категорию?',
      alert_remove_cateory_message: 'Если убрать категорию, то все связанные с ней услуги будут исключены из заказа.',
      alert_remove_cateory_btn_confirm: 'Удалить',
      error_empty_services: 'Выберите услуги для продолжения!',
    },

    // Выбор исполнителя
    // url: /visits/new/0/doers
    // url: /visits/preentry/0/doers
    visit_new_doers: {
      title: 'Выбор исполнителя',
      doers_card_title: 'Выберите исполнителя',
      btn_next: 'Продолжить',
      error_doer_all_required: 'Выберите исполнителя для продолжения!',
    },

    // Выбор скидок
    // url: /visits/new/0/discounts
    // url: /visits/preentry/0/discounts
    visit_new_discounts: {
      title: 'Скидка',
      discounts_card_title: 'Выберите скидку',
      btn_create_visit: 'Разместить заказ',
      btn_update_couterparty: 'Сохранить',
      btn_loading_create_visit: 'Создание заказа...',
      message_visit_created: 'Заказ успешно создан!',
      error_fail_upload_photo: 'Не удалось загрузить изображение',
    },

    // Выбор даты и времени для предварительной записи
    // url: /visits/preentry/datetime
    visits_new_preentry_datetime: {
      title: 'Выбор даты и времени',
      tab_current_day: 'Сегодня',
      tab_next_day: 'Завтра',
      tab_select_date: 'Выбрать дату',
      modal_confirm_title: 'Подтверждение',
      modal_confirm_btn: 'Подтвердить',
      modal_info_label_time: 'Время оказания услуги',
      modal_info_label_date: 'Дата',
      modal_info_label_box_name: 'Бокс',
      modal_info_label_box_number: 'Номер бокса',
      error_empty_datetime: 'Не выбрано время записи',
    },

    // История автомобиля
    // url: /visits/car/:carId/history
    visits_car_history: {
      title: 'История визитов {carNumber}',
      popular_services_card_title: 'Популярные услуги',
      section_visits_title: 'История визитов',
      visits_not_found: 'Нет визитов',
    },

    // Просмотр календаря предварительной записи
    // url: /visits/preentry
    visits_preentry: {
      tab_current_day: 'Сегодня',
      tab_next_day: 'Завтра',
      tab_select_date: 'Выбрать дату',
      btn_add_preentry: 'Добавить заказ',
    },

    // Добавление фото к визиту, камера с превью
    // url: /visits/:visitId/photo/add
    visits_add_photo: {
      title: 'Сделать фото',
      create_photo_button: 'Сделать фото автомобиля',
      create_photo_save_processing: 'Обработка...',
    },

    // Добавление фото к визиту, подтверждение загрузки на сервер
    // url: /visits/:visitId/photo/confirm
    visits_add_photo_confirm: {
      title: 'Сохранить фото',
      upload_confirm_button: 'Подтвердить',
      upload_confirm_processing_button: 'Подождите...',
    },
    //#endregion Visits

    //#region Market
    // Страница магазина
    // url: /market
    market: {
      title: 'Магазин',
      provide_services_card_title: 'Выберите наименование',
      btn_next: 'Продолжить',
      error_empty_products: 'Выберите товары из списка и нажмите продолжить',
      header_error_empty_products: 'Товары не выбраны',
    },

    // Страница оформления заказа в магазине (только смартфоны)
    // url: /market/checkout
    market_checkout: {
      title: 'Детали заказа',
    },
    //#endregion Market

    //#region Pos
    // Страница кассы
    // url: /pos
    pos: {
      title: 'Касса',
      item_open_pos_session: 'Открыть кассовую смену',
      item_cash_in: 'Внесение наличных',
      item_cash_out: 'Изъятие наличных',
      item_report_x: 'X-отчет',
      item_report_z: 'Z-отчет',
      item_acquiring_revise: 'Сверка итогов',
      alert_confirm_btn_confirm: 'Да',
      alert_confirm_btn_cancel: 'Нет',
      alert_confirm_open_session_header: 'Открыть смену',
      alert_confirm_open_session_message: 'Подтвердите открытие',
      alert_confirm_report_x_header: 'X-отчет',
      alert_confirm_report_x_message: 'Вы действительно хотите распечатать X-отчет?',
      alert_confirm_report_z_header: 'Z-отчет',
      alert_confirm_report_z_message: 'Вы действительно хотите распечатать Z-отчет?',
      header_error_pos: 'Ошибка кассы',
    },

    // Внесение или изъятие наличных кассы
    // url: /pos/cash/in
    // url: /pos/cash/out
    pos_cash_in_or_out: {
      title_in: 'Внесение наличных',
      title_out: 'Изъятие наличных',
      input_comment: 'Комментарий',
      btn_confirm: 'Подтвердить',
      btn_processing: 'Отработка...',
      message_success_operation: 'Успешная операция!',
      header_error_operation: 'Ошибка кассы',
    },
    //#endregion Pos

    //#region Pricelist
    // Страница прайс-листа (выбор типа услуг)
    // url: /pricelist
    pricelist: {
      title: 'Прайс',
    },

    // Страница выбора категрии прайс-листа (только смартфон)
    // url: /pricelist/:typeId/cat
    pricelist_cat: {
      default_title: 'Категория',
    },

    // Прайс-листвыбранной категории (только смартфон)
    // url: /pricelist/:typeId/cat/:catId
    pricelist_services: {
      default_title: 'Список услуг',
    },

    // Прайс-лист со списком выбора категрии (планшет и десктопы)
    pricelist_cat_and_services: {
      default_title: 'Список услуг',
    },
    //#endregion Pricelist

    //#region Reports
    // Отчеты
    // url: /reports
    reports: {
      title: 'Отчеты',
      item_report_current: 'За эту смену',
      item_report_previous: 'За прошлую смену',
      midal_report_current_title: 'За эту смену',
      midal_report_previous_title: 'За прошлую смену',
    },

    // Модальное окно с PDF отчетом
    report_modal: {
      loading_document: 'Загрузка документа...',
      document_undefined: 'Не указан документ',
    },
    //#endregion Reports

    // Страница редактирования информации о пользователе
    // url: /client/car/:carId/edit
    user_edit: {
      title: 'Редактирование информации',
      popular_services_card_title: 'Популярные услуги',
      form_title: 'Клиент',
      input_client_name: 'Имя',
      input_client_phone: 'Телефон',
      input_client_comment: 'Комментарий',
      btn_loading_save: 'Подождите...',
      btn_save: 'Сохранить',
      header_error_saved: 'Данные не обновлены',
    },

    // Всплывающие и модальные окна настроек (профиля и приложения)
    // NOTE: объединены несколько компонентов
    settings: {
      user_popover_title: 'Личный профиль',
      settings_modal_title: 'Настройки',
      settings_popover_title: '@:views.settings.settings_modal_title',
    },

    // Всплывающие модальное окно с уведомлениями
    notifications: {
      notifications_modal_title: 'Уведомления',
      notifications_list_empty: 'Нет уведомлений',
    },
  },
  components: {
    error_block: {
      btn_reload: 'Повторить попытку',
    },

    spinner: {
      loading: 'Загрузка...',
    },

    //#region Layout
    app_footer: {
      menu_item_visits: 'Заказы',
      menu_item_pricelist: 'Прайс',
      menu_item_market: 'Магазин',
      menu_item_pos: 'Касса',
      menu_item_reports: 'Отчеты',
    },

    app_header_new_visit: {
      alert_close_header: 'Подтвердите закрытие',
      alert_close_message: 'После закрытия заказа, все введеные данные будут потеряны.',
      alert_close_btn_confirm: 'Закрыть',
    },
    //#endregion Layout

    //#region Visits
    // Большие карточки визитов
    visit_card: {
      car_no_number: 'Без номера',
      remaining_work_time: 'Выезд: {time}',
      start_time: 'Заезд: {time}',
      item_label_preentry_date: 'Дата записи',
      item_label_preentry_time: 'Время записи',
      item_label_visit_datetime: 'Дата посещения',
      item_label_start_datetime: 'Время заезда',
      item_label_end_datetime: 'Время выезда',
      item_label_box_name: 'Бокс',
      item_label_box_number: 'Номер бокса',
    },

    // Маленькие карточки визитов
    visit_card_mini: {
      car_no_number: '@:components.visit_card.car_no_number',
      visit_finish: 'Выполнен: {datetime}',
      preentry_datetime: '{date}, на {time}',
    },

    // Поиск по архиву
    visit_archive_search: {
      not_found: 'Ничего не найдено',
      found_info: 'Найдено записей: {count}',
    },

    // Компонент календаря с визитами
    visits_preentry_shedule_box: {
      visit_card_client_no_name: 'Без имени',
      visit_card_car_no_number: 'Без номера',
      shedule_loading: 'Загружаем расписание',
      col_time_label: 'Время',
    },

    // Модальное окно выбора способа оплаты
    payment_type_modal: {
      modal_title: 'Выбирете способ оплаты',
      switch_use_points_label: 'Использовать {points} @:share.plur_points',
    },
    //#endregion Visits

    //#region Store
    // Блок оформления заказа в магазине
    visit_store_checkout: {
      title: 'Детали заказа',
      item_invoice: 'Распечатать пречек',
      item_choice_discount: 'Выбрать скидку',
      item_clear_order: 'Очистить заказ',
      btn_pay: 'Принять оплату {total}',
      btn_pay_loading: 'Подождите...',
      alert_clear_order_header: 'Очистка заказа',
      alert_clear_order_message: 'Данный заказ был создан и не оплачен, вы действительно хотите закрыть его без оплаты?',
      alert_invoice_header: 'Пречек',
      alert_invoice_message: 'Подтвердите действие',
      alert_invoice_btn_confirm: 'Отправить',
      message_invoice_success: 'Пречек успешно отправлен',
      header_invoice_error: 'Пречек не создан',
      header_save_order_error: 'Не удалось создать заказ',
    },
    //#endregion Store

    //#region Services
    // Добавление категории к оказываемым услугам
    // или редактирование списка услуг в заказе
    add_category_modal: {
      view_point_type_title: 'Добавить тип услуги',
      view_category_title: 'Выберите категорию',
      view_provided_services_title_default: 'Выберите услуги',
      view_provided_services_label_total: 'Итого:',
      view_provided_services_btn_next: 'Применить',
      view_doers_title: 'Исполнители',
      view_doers_btn_next: 'Продолжить',
      view_doers_btn_apply: 'Применить изменения',
      view_discount_title: 'Скидка',
      view_discount_btn_apply: 'Применить изменения',
      error_doer_all_required: 'Выберите исполнителя для продолжения!',
    },

    // Общая карточка, где отображаются группы
    // услуг и их итоговая стоимость
    services_provided_view_card: {
      card_item_label_total: 'Итоговая стоимость:',
    },

    // Карточка с популярными услугами автомобиля
    services_popular_card: {
      col_label_name: 'Наименование',
      col_label_count: 'Кол-во',
      services_list_empty: 'Нет услуг',
    },

    // Отбражения списка услуг в разделе прайс-листа
    services_view: {
      text_category_empty_selected: 'Выберите категорию',
      input_search: 'Поиск…',
    },

    // Модальное окно для редактирование цены услуги
    service_provide_item_cost_modal: {
      modal_title: 'Изменение цены',
      btn_apply: 'Применить',
    },

    // Компонент со списком выбора услуг
    services_provide_visit_state: {
      input_search: 'Поиск…',
      label_total: 'Итого:',
    },

    // Элемент для выбора скидки для оказываемой услуги
    service_provide_discount_item: {
      modal_title: 'Выберите скидку',
    },
    //#endregion Services

    //#region Car & Client
    // Информация о клиенте.
    // Используется в разделах оформления нового заказа
    client_side_info: {
      client_card_info_title: 'Клиент',
    },

    // Информационная карточка о клиенте
    client_info_card: {
      client_name_empty: 'Добавить данные о клиенте',
      item_car_history: 'История визитов',
      item_client_phone: 'Телефон владельца',
      item_group: 'Контрагент',
      client_phone_empty: 'Добавить',
    },
    //#endregion Car & Client

    //#region Group partner
    // Карточка для ввода контрагента
    group_partner_input_card: {
      label: 'Контрагент',
      btn_edit: 'Изменить',
      btn_select: 'Выбрать контрагента',
    },

    // Модальное окно для выбора контрагента
    group_partner_select_box: {
      modal_title: 'Выбор контрагента',
      option_empty: 'Без контрагента',
      input_search: 'Поиск…',
      options_empty: 'Список пуст',
      options_not_found: 'Ничего не найдено',
    },
    //#endregion Group partner

    //#region Settings (& App services)
    app_settings: {
      label_input_cid: 'Введите номер вашего Core ID',
      label_input_language: 'Язык приложения',
      btn_apply_cid: 'Применить указанный номер',
      label_network_speed: 'Скорость интернет-соединения',
      label_number_recognition: 'Автоматическое распознавания номера',
      description_number_recognition: 'В связи с внешними факторами cистема не гарантирует 100% распознавание регистрационного номера автомобиля',
      label_preentry: 'Предварительная запись',
      description_preentry: 'На главном экране приложения будет отображаться кнопка для создания визита предварительной записи',
      label_flashlight: 'Фонарик',
      description_flashlight: 'В карточке созданного визита будет отображаться кнопка включения фонарика на вашем устройстве',
      btn_support_chat: 'Написать в поддержку',
      btn_clear_cache: 'Сбросить кэш',
      btn_view_privacy_policy: 'Посмотреть политику',
      btn_community: 'Вступить в лигу админов',
      alert_clear_cache_header: 'Сброс кэша',
      alert_clear_cache_message: 'При сбросе удалятся все полученные с сервера данные.',
      alert_clear_cache_btn_confirm: 'Сбросить',
    },
    user_settings: {
      fullname_label: 'Администратор смены',
      btn_exit: 'Выйти',
      alert_exit_header: 'Подтвердить выход',
      alert_exit_message: 'После выхода из аккаунта вы будете перенаправлены на страницу входа',
      alert_exit_btn_confirm: 'Выйти',
    },
    login_form: {
      input_login: 'Логин',
      input_password: 'Пароль',
      btn_login: 'Войти',
      btn_login_loading: 'Загружаем...',
      message_success_logged_in: 'Успешный вход!',
      error_logged_in_incorrect_login_or_password: 'Проверьте правильность имени пользователя и пароля',
      header_error_logged_in_incorrect_login_or_password: 'Неверные учетные данные',
      header_error_not_authorized: 'Не авторизован',
      alert_coreid_header: 'Введите ваш Core ID',
      alert_coreid_message: 'Core ID можно найти в адресной строке браузера личного кабинета управляющего',
      alert_btn_open_settings: 'Ввести Core ID',
    },
    //#endregion Settings (& App services)
  },
  composables: {
    alert: {
      confirm_default_btn_cancel: 'Отменить',
      confirm_default_btn_confirm: 'Подтвердить',
    },

    visit_timings: {
      progress_text_wait: 'Ожидание',
      progress_text_completed: 'Выполнен',
      progress_text_time_over: 'Время вышло',
      progress_text_time_left: 'Осталось: ~{left}',
    },

    visit_badges: {
      label_closed: 'Закрыт',
      label_finished: 'Выполнен',
      label_preentry: 'По записи',
      label_opened: 'Открыт',
      label_canceled: 'Отменен',
      label_paid: 'Оплачен',
      label_not_paid: 'Не оплачен',

      // Временно отключен
      label_not_sync: 'Не синхронизирован',
    },

    visit_archive_group: {
      action_show_text: 'Показать еще {count} @:share.plur_order',
      action_hide_text: 'Скрыть {count} @:share.plur_order',
    },

    visit_preentry: {
      header_error_load_data: 'Данные не получены',
    },

    discount: {
      modal_choice_title_default: 'Выберите скидку',
    },

    app_check_update: {
      open_app_store_error: 'Не удалось открыть магазин',
      open_app_store_error_not_supported: 'Не поддерживается на данной платформе',
      alert_info_header: 'Обновите приложение',
      alert_info_message: 'Мы обновили приложение, чтобы пользоваться Core12 стало еще проще.',
      alert_info_btn_update: 'Обновить',
      alert_info_btn_update_skip: 'Обновить позже',
    },
  },
  share: {
    plur_order: 'заказов | заказ | заказа | заказов',
    plur_points: 'бонусов | бонус | бонуса | бонусов',
  }
} as LocalizationDictionary;