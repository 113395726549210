import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, createBlock as _createBlock, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "visit-card__image-container" }
const _hoisted_2 = { class: "visit-card-badge-container" }
const _hoisted_3 = { class: "visit-card__header" }
const _hoisted_4 = { class: "visit-card__title" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 1,
  class: "text-muted"
}
const _hoisted_7 = {
  key: 0,
  class: "visit-card__total"
}
const _hoisted_8 = { class: "visit-card__content" }
const _hoisted_9 = {
  key: 0,
  style: { height: '6px' }
}
const _hoisted_10 = { class: "visit-card__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_cc = _resolveComponent("cc")!
  const _component_c_progress_bar = _resolveComponent("c-progress-bar")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, { class: "visit-card" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.badgeList, (badge, index) => {
            return (_openBlock(), _createBlock(_component_ion_badge, {
              key: index,
              color: badge.color,
              textContent: _toDisplayString(badge.text)
            }, null, 8, ["color", "textContent"]))
          }), 128)),
          _renderSlot(_ctx.$slots, "badge:after")
        ]),
        _createElementVNode("div", {
          class: "visit-card__image",
          style: _normalizeStyle({
        backgroundImage: `url('${_ctx.mainImage}')`
      })
        }, null, 4)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_ctx.hasCarNumber)
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.formatCarNumber), 1))
            : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t('components.visit_card.car_no_number')), 1))
        ]),
        (_ctx.visit.price)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(_component_cc, { price: _ctx.totalPrice }, null, 8, ["price"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_8, [
        (_ctx.isPreentry)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9))
          : _createCommentVNode("", true),
        (!_ctx.isClosed && !_ctx.isPreentry)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createVNode(_component_c_progress_bar, {
                color: _ctx.progressColor,
                value: _ctx.progressValue
              }, null, 8, ["color", "value"]),
              _createVNode(_component_ion_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, { class: "px-0" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('components.visit_card.start_time', { time: _ctx.startTimeFormat || '–' })), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, {
                    class: "px-0 ion-text-end",
                    size: "auto"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('components.visit_card.remaining_work_time', { time: _ctx.predictedEndTimeFormat || '∞' })), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ], 64))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_10, [
        (_ctx.viewBox && _ctx.boxInfo)
          ? (_openBlock(), _createBlock(_component_ion_item, { key: 0 }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.boxInfo.label), 1),
                _createVNode(_component_ion_note, {
                  class: "color-default",
                  slot: "end"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.boxInfo.name), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.isPreentry)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createVNode(_component_ion_item, {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click-preentry-date')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('components.visit_card.item_label_preentry_date')), 1),
                  _createVNode(_component_ion_note, {
                    class: "color-default",
                    slot: "end"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.preentryDateFormat), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('click-preentry-time')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('components.visit_card.item_label_preentry_time')), 1),
                  _createVNode(_component_ion_note, {
                    class: "color-default",
                    slot: "end"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.preentryTimeFormat), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ], 64))
          : (_ctx.isClosed)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                (_ctx.createDateFormat)
                  ? (_openBlock(), _createBlock(_component_ion_item, { key: 0 }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('components.visit_card.item_label_visit_datetime')), 1),
                        _createVNode(_component_ion_note, {
                          class: "color-default",
                          slot: "end"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.createDateFormat), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.startTimeFormat)
                  ? (_openBlock(), _createBlock(_component_ion_item, { key: 1 }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('components.visit_card.item_label_start_datetime')), 1),
                        _createVNode(_component_ion_note, {
                          class: "color-default",
                          slot: "end"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.startTimeFormat), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.endTimeFormat)
                  ? (_openBlock(), _createBlock(_component_ion_item, { key: 2 }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('components.visit_card.item_label_end_datetime')), 1),
                        _createVNode(_component_ion_note, {
                          class: "color-default",
                          slot: "end"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.endTimeFormat), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ], 64))
            : (_openBlock(), _createBlock(_component_ion_item, {
                key: 3,
                class: "color-default"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    slot: "start",
                    name: "core-timer",
                    class: "mr-2 size-default"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.progressText), 1)
                ]),
                _: 1
              }))
      ])
    ]),
    _: 3
  }))
}