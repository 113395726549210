
import { defineComponent, ref, DeepReadonly, computed } from 'vue'
import AppHeaderNewVisit from '@/components/layout/AppHeaderNewVisit.vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/composables/useApp'
import { PointTypeCategoryItem, ThisClientPointTypeItem } from '@/repositories/Models/Point'
import ClientInfoCard from '@/components/car/ClientInfoCard.vue'
import { isTabletDown } from '@/helpers/adaptive'
import { useCreateVisitStep, useNewVisitPhoto } from '@/composables/visit'
import { toNumber } from 'lodash'
import { useMetrica } from '@/composables/useMetrica'

export default defineComponent({
  components: {
    AppHeaderNewVisit,
    ClientInfoCard,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const { carId, visitId, visitState, isPreentry, visitType, prefixNamePage } = useCreateVisitStep();
    const { emitEvent } = useMetrica();
    const { visitImageCover } = useNewVisitPhoto({ visitState, onlyMeta: isPreentry });

    const typeId = toNumber(route.params.typeId);

    const pointType = ref<DeepReadonly<ThisClientPointTypeItem|null>>(null);
    const categories = computed<DeepReadonly<PointTypeCategoryItem[]>>(() => {
      return pointType.value?.carCategories || [];
    });

    async function loadServiceCategory() {
      pointType.value = await store.point.filterTypePoint(typeId) || null;
    }

    function selectCategory(cat: DeepReadonly<PointTypeCategoryItem>) {
      emitEvent(`visit/${visitType}/category`, cat);

      // Сохраняем первую выбранную категорию, чтобы в дальнейшем
      // отображать ее в интерфейсе во время выбора услуг
      visitState.selectedTypeAndCategories = [
        {
          typeId: typeId,
          catId: cat.id
        }
      ];

      router.push({
        name: prefixNamePage('services'),
        params: { visitId },
      });
    }

    const lastCategoryId = visitState.meta.lastCategoryId;

    return {
      loadServiceCategory,
      selectCategory,
      categories,
      carId,
      visitImageCover,
      isTabletDown,
      lastCategoryId,
    };
  }
});
