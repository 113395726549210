
import { defineComponent, ref, computed, watch } from 'vue';
import { useApp } from '@/composables/useApp';
import { testValidBaseUrl } from '@/utils/domain';
import { useNavManager } from '@/composables/useNavManager';
import config from '@/config';
import NetworkIndicator from '@/components/app-service/NetworkIndicator.vue';
import { useRouter } from 'vue-router';
import { useMetrica } from '@/composables/useMetrica';
import CidInput from './CidInput.vue';
import { useAlert } from '@/composables/alert';
import { useI18n } from 'vue-i18n';
import { useFlashlight } from '@/composables/flashlight';
// import toast from '@/ext/toast';
// import { createProgressInformation } from '@/composables/progress';
// import LocaleInput from './LocaleInput.vue';

export default defineComponent({
  components: {
    NetworkIndicator,
    CidInput,
    // LocaleInput,
  },

  emits: ['logout', 'clear-cache', 'close-settings'],

  setup(props, { emit }) {
    const app = useApp();
    const router = useRouter();
    const navManager = useNavManager();
    const { emitEvent } = useMetrica();
    const { createAlertConfirm } = useAlert();
    const { t } = useI18n();

    let currentApiUrl = app.getApiDomain();
    const apiUrl = ref<string>(currentApiUrl);
    const isDifferentApiUrls = computed(() => apiUrl.value !== currentApiUrl);
    const validApiUrl = computed(() => testValidBaseUrl(apiUrl.value));

    async function applyBaseUrl() {
      try {
        // После смены url необходимо заного авторизоваться
        await app.store.user.logout();
      } finally {
        app.setApiDomain(apiUrl.value);
        apiUrl.value = currentApiUrl = app.getApiDomain();

        navManager.navigate({
          routerDirection: 'root',
          routerLink: { name: 'login' },
        });
      }

      emitEvent('app/settings/set-domain', { domain: apiUrl.value });
      emit('close-settings');
    }

    const clearCacheAction = createAlertConfirm({
      header: t('components.app_settings.alert_clear_cache_header'),
      message: t('components.app_settings.alert_clear_cache_message'),
      confirmBtnText: t('components.app_settings.alert_clear_cache_btn_confirm'),
      async confirm() {
        emitEvent('app/settings/cache-clear');
        await app.clearCacheJob();
        emit('clear-cache');
      }
    });

    const useNumberRecognition = app.store.config.useNumberRecognition;
    watch(useNumberRecognition, status => {
      const textStatus = status ? 'on' : 'off';
      emitEvent(`app/settings/number-recognition/${textStatus}`);
    });

    const usePreentry = app.store.config.usePreentry;
    watch(usePreentry, status => {
      const textStatus = status ? 'on' : 'off';
      emitEvent(`app/settings/preentry/${textStatus}`);
    });

    const { isAvailable: flashlightIsAvailable } = useFlashlight();
    const useFlashlightValue = app.store.config.useFlashlight;
    watch(useFlashlightValue, status => {
      const textStatus = status ? 'on' : 'off';
      emitEvent(`app/settings/flashlight/${textStatus}`);
    });

    const showDevLinkPage = computed(() => /\/\/cid828\.core12\.ru/.test(app.getApiDomain()));
    function toDevPage() {
      router.push({ name: 'dev' });
      emit('close-settings');
    }

    const contactSupport = () => emitEvent('app/settings/support');
    const viewPrivacyPolicy = () => emitEvent('app/settings/view-privacy-policy');
    const joinCommunity = () => emitEvent('app/settings/join-community');

    return {
      apiUrl,
      isDifferentApiUrls,
      validApiUrl,
      applyBaseUrl,
      useNumberRecognition,
      clearCacheAction,
      showDevLinkPage,
      toDevPage,
      supportChatUrl: config.supportChatUrl,
      privacyPolicyLink: config.privacyPolicyLink,
      contactSupport,
      viewPrivacyPolicy,
      usePreentry,
      useFlashlightValue,
      flashlightIsAvailable,
      communityLink: config.communityLink,
      joinCommunity,
    };
  }
});
