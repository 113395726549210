import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_c_choice_list_item = _resolveComponent("c-choice-list-item")!

  return (_openBlock(), _createBlock(_component_c_choice_list_item, { class: "c-service-provide-discount-item" }, _createSlots({
    buttons: _withCtx(() => [
      _createVNode(_component_ion_button, { onClick: _ctx.openModalDiscount }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    "view-selected": _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.viewDiscountSelected), 1)
    ]),
    _: 2
  }, [
    (_ctx.service)
      ? {
          name: "default",
          fn: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.serviceName), 1)
          ])
        }
      : undefined
  ]), 1024))
}