
import { defineComponent, PropType } from 'vue';
import { Discount } from '@/repositories/Models/Discount';

export default defineComponent({
  inheritAttrs: false,
  props: {
    item: {
      type: Object as PropType<Discount>,
      required: true,
    }
  },
});
