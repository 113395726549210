import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "c-modal-select-option payment-type-points-switch-option" }
const _hoisted_2 = { class: "c-modal-select-option__icon-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_toggle = _resolveComponent("ion-toggle")!
  const _component_c_modal_view_select = _resolveComponent("c-modal-view-select")!

  return (_openBlock(), _createBlock(_component_c_modal_view_select, {
    "set-value": _ctx.setValueCallback,
    multiselect: false,
    autoclose: true,
    options: _ctx.options,
    "modal-title": _ctx.$t('components.payment_type_modal.modal_title')
  }, _createSlots({ _: 2 }, [
    (_ctx.hasPoints)
      ? {
          name: "select-after",
          fn: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", {
                class: "c-modal-select-option__label",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.targetSwitch(_ctx.$refs.pointsSwitch)))
              }, _toDisplayString(_ctx.pointsLabel), 1),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_ion_toggle, {
                  class: "core-toggle",
                  modelValue: _ctx.usePoints,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.usePoints) = $event)),
                  ref: "pointsSwitch"
                }, null, 8, ["modelValue"])
              ])
            ])
          ])
        }
      : undefined
  ]), 1032, ["set-value", "options", "modal-title"]))
}