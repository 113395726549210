
import { computed, defineComponent, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { CarVisitCollectionItem } from '@/repositories/Models/CarVisit';
import { useStore } from '@/composables/useApp';
import VisitCard from '@/components/visit/VisitCard.vue';
import VisitCardMini from '@/components/visit/VisitCardMini.vue';

export default defineComponent({
  components: {
    VisitCard,
    VisitCardMini,
  },
  props: {
    viewType: {
      type: String,
      default: 'normal'
    }
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    const visitsCounters = store.visit.getCountersRef();
    const visitsProcessing = ref<CarVisitCollectionItem[]>([]);

    const cardComponent = computed(() => props.viewType === 'mini' ? 'VisitCardMini' : 'VisitCard');

    async function loadVisits() {
      visitsProcessing.value = await store.visit.getWorkVisits();
    }

    let emptyInited = !!visitsCounters.value.emptyInited;
    watch(visitsCounters, () => {
      // Чтобы при первом запуске не делать лишний повторный запрос 
      if (emptyInited === true) {
        emptyInited = false;
        return;
      }

      loadVisits();
    }, { deep: true });

    function onClickCard(visit: CarVisitCollectionItem) {
      router.push({
        name: 'visit-single',
        params: { visitId: visit.id }
      });
    }

    return {
      visitsProcessing,
      loadVisits,
      onClickCard,
      cardComponent,
    };
  },
});
