import { AppUpdate } from '@robingenz/capacitor-app-update';
import { isPlatform, alertController } from '@ionic/vue';
import { compareVersions } from '@/utils/version';
import { useToast } from '@/composables/toast';
import { useI18n } from 'vue-i18n';
import { minutes } from '@/helpers/datetime';
import { isOffline } from '@/helpers/network';

export function useAppCheckUpdate() {
  const appCountry = 'ru'; // NOTE: Обязательно должна быть актуальной, иначе вернет 0 строк информации (only iOS)

  const isCapacitor = isPlatform('capacitor');
  const toast = useToast();
  const { t } = useI18n();

  /**
   * Проверяет доступна ли новая версия приложения 
   * и если доступна, то показывает модельное окно.
   * 
   * @returns 
   */
  async function startCheck() {
    const isUpd = await isNeedsUpdating();

    if (isUpd) {
      await showModalInformation();
    }

    return isUpd;
  }

  /**
   * Проверка (требует ли приложение обновления)
   * @returns Если true - то приложение требует обновления
   */
  async function isNeedsUpdating() {
    // В режиме оффлайн проверить невозможно
    if (isOffline()) {
      return false;
    }

    // Если не Capacitor, то проверка не имеет смысла
    if (false === isCapacitor) {
      return false;
    }

    const info = await AppUpdate.getAppUpdateInfo({ country: appCountry });
    const versionResult = compareVersions(info.availableVersion, info.currentVersion);

    return (versionResult > 0);
  }

  /**
   * Открыть мазагин с приложением. 
   * Если отрыть не удается, то отображается ошибка (tost)
   */
  async function openAppStore() {
    try {
      if (false === isCapacitor) {
        throw new Error(t('composables.app_check_update.open_app_store_error_not_supported'));
      }

      await AppUpdate.openAppStore({ country: appCountry });
    } catch (e: any) {
      toast.error(e, 5000, {
        defaultHeader: t('composables.app_check_update.open_app_store_error')
      });
    }
  }

  /**
   * Отображает модальное окно с сообщением об обновлении
   * 
   * @returns 
   */
  async function showModalInformation() {
    const alert = await alertController.create({
      backdropDismiss: false,
      cssClass: 'c-alert-buttons-stack с-modal-backdrop-no-opacity',
      header: t('composables.app_check_update.alert_info_header'),
      message: t('composables.app_check_update.alert_info_message'),
      buttons: [
        {
          text: t('composables.app_check_update.alert_info_btn_update_skip'),
          role: 'cancel'
        },
        {
          text: t('composables.app_check_update.alert_info_btn_update'),
          handler: openAppStore
        },
      ],
    });

    alert.present(); // async
    return alert;
  }

  /**
   * Запуск постоянной проверки, через каждое кол-во меинут.
   * 
   * @param min 
   * @returns функция для отключения таймера
   */
  function repeatChecking(min: number) {
    const ms = minutes(min);
    const timer = setInterval(startCheck, ms);

    return () => clearInterval(timer);
  }

  return {
    startCheck,
    openAppStore,
    isNeedsUpdating,
    showModalInformation,
    repeatChecking,
  };
}

