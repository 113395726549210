
import { defineComponent, onMounted, ref, PropType, computed, watch } from 'vue';
import { createGesture, GestureDetail, Gesture } from '@ionic/vue';

export type StateValue = 'closed'|'preview'|'opened';

const EDGE_ACTION_OFFSET_PX = 60;

export default defineComponent({
  emits: ['update:state', 'closed', 'preview', 'opened', 'click-backgroup'],

  props: {
    previewHeight: {
      type: Number,
      required: true,
    },
    state: {
      type: String as PropType<StateValue>,
      validate: (v: string) => ['closed', 'preview', 'opened'].includes(v), 
    }
  },

  setup(props, { emit }) {
    const modalContainer = ref<HTMLDivElement|null>(null);
    const isMove = ref(false);
    let moveTranslateYStart = 0;
    const moveTranslateY = ref(0);
    const translateYUnit = computed<string>(() => {
      if (isMove.value) return `${moveTranslateY.value}px`;

      switch (lazyValue.value) {
        case 'closed': return '100%';
        case 'preview': return `calc(100% - ${props.previewHeight}px)`;
      }

      return '0';
    });

    function getTranslateYStart() {
      if (isMove.value) return moveTranslateY.value;
      if (!modalContainer.value) return 0;

      switch (lazyValue.value) {
        case 'closed': return modalContainer.value.clientHeight;
        case 'preview': return modalContainer.value.clientHeight - props.previewHeight;
      }

      return 0; // opened
    }

    const lazyValue = ref<StateValue>(props.state || 'closed');
    watch(() => props.state, value => lazyValue.value = value || 'closed');

    function setState(value: StateValue) {
      lazyValue.value = value;
      emit('update:state', value);
      emit(value);
    }

    function onStart() {
      moveTranslateYStart = getTranslateYStart();
      moveTranslateY.value = moveTranslateYStart;
      isMove.value = true;
    }

    function onMove(ev: GestureDetail) {
      if (!modalContainer.value) return;

      const modalHeight = modalContainer.value.clientHeight;
      const correctOffset = Math.max(0, Math.min(modalHeight, ev.deltaY + moveTranslateYStart));
      moveTranslateY.value = correctOffset;
    }

    function onEnd(ev: GestureDetail) {
      if (!modalContainer.value) return;

      const modalHeight = modalContainer.value.clientHeight;
      const correctOffset = Math.max(0, Math.min(modalHeight, ev.deltaY + moveTranslateYStart));

      let newValue: StateValue;

      if (correctOffset < EDGE_ACTION_OFFSET_PX) {
        newValue = 'opened';
      } else if (correctOffset + EDGE_ACTION_OFFSET_PX > modalHeight) {
        newValue = 'closed';
      } else {
        newValue = 'preview';
      }

      isMove.value = false;
      setState(newValue);
    }

    let gesturePointer: Gesture|null = null;
    onMounted(() => {
      if (!modalContainer.value) return;

      gesturePointer = createGesture({
        el: modalContainer.value,
        threshold: 15,
        direction: 'y',
        gestureName: 'modal-move',
        onStart: onStart,
        onMove: onMove,
        onEnd: onEnd,
      });

      gesturePointer.enable();
    });

    return {
      modalContainer,
      translateYUnit,
      setState,
      isMove,
      lazyValue,
      open: () => setState('opened'),
      close: () => setState('closed'),
      preview: () => setState('preview'),
    };
  },
});
