import { createI18n } from 'vue-i18n';
import { getValue } from '@/helpers/localStorage';
import { LocalizationDictionary } from './types';
import { includes } from 'lodash';
import moment from 'moment';

import ru from './locales/ru';
import en from './locales/en';

// Поддерживаемые локально локали
export const SUPPORT_LOCAL_LOCALES = ['ru', 'en'];

function pluralizationRuRule(choice: number, choicesLength: number /*, orgRule: unknown*/) {
  if (choice === 0) {
    return 0
  }

  const teen = choice > 10 && choice < 20
  const endsWithOne = choice % 10 === 1
  if (!teen && endsWithOne) {
    return 1
  }
  if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
    return 2
  }

  return choicesLength < 4 ? 2 : 3
}

export const LS_KEY_LANGUAGE = 'language';
export const LS_KEY_PREFIX_LANGUAGE_MESSAGES = 'language/messages/';

function setupI18n() {
  // TODO: Временно отключено для клиентской версии
  const savedLocale = /* localStorage.getItem(LS_KEY_LANGUAGE) || */ 'ru';
  let messages: Record<string, LocalizationDictionary> = { ru, en };

  if (!SUPPORT_LOCAL_LOCALES.includes(savedLocale)) {
    const cachedLanguageMessages = getValue(LS_KEY_PREFIX_LANGUAGE_MESSAGES + savedLocale);
    if (cachedLanguageMessages) {
      messages[savedLocale] = cachedLanguageMessages;
    }
  }

  moment.locale(includes(moment.locales(), savedLocale) ? savedLocale : 'ru');

  return createI18n({
    locale: savedLocale,
    fallbackLocale: 'en',
    pluralizationRules: {
      ru: pluralizationRuRule
    },
    messages,
  });
}

export const i18n = setupI18n();
