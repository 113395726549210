
import { defineComponent, DeepReadonly, ref } from 'vue';
import { useStore } from '@/composables/useApp';
import { ThisClientPointTypeItem } from '@/repositories/Models/Point';
import { useRouter } from 'vue-router';
import { isTabletAndUp } from '@/helpers/adaptive';
import { useMetrica } from '@/composables/useMetrica';
import { pick } from 'lodash';

export default defineComponent({
  setup() {
    const store = useStore();
    const router = useRouter();
    const { emitEvent } = useMetrica();

    const pointTypes = ref<DeepReadonly<ThisClientPointTypeItem[]>>([]);
    async function loadPointTypes() {
      const data = await store.point.getThisClient();
      pointTypes.value = data.pointTypes;
    }

    function selectPointType(pointType: DeepReadonly<ThisClientPointTypeItem>) {
      emitEvent('pricelist/category-type', pick(pointType, ['id', 'name']));
      
      router.push({
        name: isTabletAndUp.value
          ? 'pricelist-category-and-services' // for tablet
          : 'pricelist-category',             // for phone
        params: { typeId: pointType.id },
      });
    }

    return {
      loadPointTypes,
      pointTypes,
      selectPointType,
    };
  }
});
