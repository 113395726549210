
import { defineComponent, PropType, ref, DeepReadonly, computed} from 'vue'
import { ThisClientPointTypeItem, PointTypeCategoryItem } from '@/repositories/Models/Point'
import { useStore } from '@/composables/useApp'
import { isEmpty } from 'lodash'

export default defineComponent({
  emits: ['type-loaded', 'select-category'],

  props: {
    typeId: {
      type: [String, Number] as PropType<string|number>,
      required: true,
    },
    selectedCatId: {
      type: [String, Number] as PropType<string|number>,
      required: false,
    },
    cardsListClasses: {
      type: null,
      default: ''
    },
    tabletAdaptive: {
      type: Boolean,
      default: true,
    },
  },

  setup(props, { emit }) {
    const store = useStore();

    const pointType = ref<DeepReadonly<ThisClientPointTypeItem|null>>(null);
    const categories = computed<DeepReadonly<PointTypeCategoryItem[]>>(() => {
      return pointType.value?.carCategories || [];
    });
    const isEmptyCategories = computed(() => isEmpty(categories.value));

    async function loadPointType() {
      pointType.value = await store.point.filterTypePoint(+props.typeId) || null;
      emit('type-loaded', pointType.value);
    }

    async function selectCategory(category: DeepReadonly<PointTypeCategoryItem>) {
      emit('select-category', category);
    }

    return {
      categories,
      loadPointType,
      selectCategory,
      isEmptyCategories,
    };
  }
});
