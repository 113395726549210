
import { defineComponent, ref, reactive, computed, onMounted } from 'vue';
import AppHeaderNewVisit from '@/components/layout/AppHeaderNewVisit.vue';
import InputCarNumber from '@/components/car/InputCarNumber.vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from '@/composables/toast';
import { useStore } from '@/composables/useApp';
import { getCarNumberPreparedAndType } from '@/utils/car';
import { useNewVisitPhoto } from '@/composables/visit';
import { CarNumberTypeEnum } from '@/repositories/Models/Car';
import { useCreateVisitStep } from '@/composables/visit';
import { useMetrica } from '@/composables/useMetrica';
import { useI18n } from 'vue-i18n';
import { get } from 'lodash';

export default defineComponent({
  components: {
    AppHeaderNewVisit,
    InputCarNumber,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const toast = useToast();
    const store = useStore();
    const { isPreentry, visitType, visitState } = useCreateVisitStep();
    const { emitEvent } = useMetrica();
    const { t } = useI18n();
    const { visitImageCover } = useNewVisitPhoto({ onlyMeta: isPreentry });

    const car = reactive({
      number: '',
      numberOther: '',
      createLoading: false,
    });

    const isOpenModal = ref(false);
    function setOpenModal(value: boolean) {
      isOpenModal.value = value;
    }

    const numberConfig = computed(() => store.config.cidConfigPrepared.value.inputCarNumber);
    const numberConfigLoaded = computed(() => !!store.config.cidConfig);
    onMounted(async () => {
      if (route.query.number) {
        // Обязательно предварительно нужно прогрузить конфиг!
        // Это может понадобится, если данную страницу сразу открыть в браузере или перезагрузить ее,
        // в этом случае конфиг скореевсего еще не успееет загрузиться с сервера.
        await store.config.loadCIDConfig();

        const recognize = getCarNumberPreparedAndType(route.query.number as string, {
          translitChars: numberConfig.value.translitChars,
          typesRecognition: numberConfig.value.typesRecognition,
        });
        if (recognize.numberType === CarNumberTypeEnum.Citizen) {
          car.number = recognize.number;
        } else {
          car.numberOther = recognize.number;
          isOpenModal.value = true;
        }
      }
    });

    /**
     * Создать новый автомобиль
     * 
     * @private
     * @throws {Error|ClientError}
     * @returns Идентификатор созданного автомобиля
     */
    async function createCar(carNumber: string) {
      try {
        const { number, numberType } = getCarNumberPreparedAndType(carNumber, {
          translitChars: numberConfig.value.translitChars,
          typesRecognition: numberConfig.value.typesRecognition,
        });

        const createInfo = await store.car.create({
          id: number, // ID -> номер автомобиля (так реализованно в предыдущем приложении для айпада)
          number,
          numberType,
        });

        // TODO: Здесь должен происходить запрос на получение последнего фото автомобиля
        // visitState.meta.visitPhotoUrl = 'https://core12.inaliv.ru/__tmp/muscule_car.jpg';
        visitState.meta.visitPhotoUrl = null;

        // Последние категрии клиента
        visitState.meta.lastCategoryId = get(createInfo.response, 'data.priceCategories.0.id', null);
        visitState.meta.lastTypeCategoryId = get(createInfo.response, 'data.priceCategories.0.pointType.id', null);

        // if (createInfo.sendingDelayed) {
        //   toast.warning('Интернет недоступен, данные сохранены, и синхрнизируются после пояления интернета');
        // }
        // else {
        //   toast.success('Автомобиль успешно добавлен')
        // }

        return createInfo.entityId;
      } catch (e) {
        toast.error(e, 5000, {
          defaultHeader: t('views.visit_new_car_number.header_error_create_car')
        });

        throw e;
      }
    }

    /**
     * Добавить автомобиль и перейти к заполнению информации о клиенте
     * 
     * @private
     */
    async function toNewVisitClient(carNumber: string) {
      if (!carNumber) {
        return toast.error(t('views.visit_new_car_number.error_empty_car_number'));
      }

      const carId = await createCar(carNumber); // throw exit

      router.push({
        name: isPreentry ? 'preentry-client' : 'visit-new-client',
        params: { carId },
      });
    }
    
    function next() {
      emitEvent(`visits/${visitType}/car-number/next/citizen`);
      toNewVisitClient(car.number);
    }

    function nextOtherCarNumber() {
      emitEvent(`visits/${visitType}/car-number/next/other`);
      toNewVisitClient(car.numberOther);
      setOpenModal(false);
    }

    return {
      car,
      visitImageCover,
      isOpenModal,
      setOpenModal,
      nextOtherCarNumber,
      next,
      isPreentry,
      numberConfig,
      numberConfigLoaded,
    };
  },
});
