import { inject, getCurrentInstance } from 'vue'
import { AnimationBuilder } from '@ionic/vue';
import { RouteLocationRaw } from 'vue-router'
import { createIonRouter } from '@ionic/vue-router/dist/types/router'

export type RouteAction = 'push' | 'pop' | 'replace';
export type RouteDirection = 'forward' | 'back' | 'root' | 'none';

export interface NavigationOptions {
  routerLink: RouteLocationRaw;
  routerDirection?: RouteDirection;
  routerAnimation?: AnimationBuilder;
}

export type NavManagerInstance = Omit<ReturnType<typeof createIonRouter>, "navigate"> & {
  navigate: (navigationOptions: NavigationOptions) => void,
};

export function useNavManager() {
  return inject('navManager') as NavManagerInstance;
}

export function useIonNav(el?: HTMLElement) {
  if (!el) {
    const instance = getCurrentInstance();
    el = instance?.vnode.el as HTMLElement|undefined;
  }
  
  const nav = el?.closest('ion-nav');
  return nav || null;
}
