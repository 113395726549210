
import { computed, defineComponent, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import ServicesPopularCard from '@/components/service/ServicesPopularCard.vue';
import { CarBodyRequest } from '@/repositories/Models/Car';
import { useStore } from '@/composables/useApp';
import { pick } from 'lodash';
import { useToast } from '@/composables/toast';
import carNoImage from '@/img/car-no-image.svg';
import { isTabletAndUp } from '@/helpers/adaptive';
import { useI18n } from 'vue-i18n';
import { preparePhoneCore12LegacyFormat } from '@/utils/string';

export default defineComponent({
  components: { ServicesPopularCard },
  setup() {
    const route = useRoute();
    const store = useStore();
    const toast = useToast();
    const router = useRouter();
    const { t } = useI18n();

    const carId = route.params.carId as string;
    const carCover = computed(() => route.query.visit_image || carNoImage);
    const car = reactive({
      loadingInfo: false,
      loadingSave: false,
      fromСacheInfo: false,
      info: {
        ownerName: '',
        ownerPhone: '',
        comment: '',
      } as CarBodyRequest,
    });

    /** Загрузка информации о владельце */
    async function loadCar() {
      car.loadingInfo = true;

      try {
        const data = await store.car.getCar(carId);
        car.fromСacheInfo = data.fromСache;

        Object.assign(car.info, pick<CarBodyRequest>(data.data, [
          'comment',
          'ownerName',
          'ownerPhone',
        ]));
      } finally {
        car.loadingInfo = false;
      }
    }

    /** Обновление информации об автомобиле */
    async function updateCar() {
      car.loadingSave = true;

      try {
        await store.car.update(carId, {
          id: carId,
          ...car.info,
          ownerPhone: preparePhoneCore12LegacyFormat(car.info.ownerPhone)
        });

        // fix: Чтобы правильно перекэшировать информацию
        await store.car.getCar(carId);
      } catch (e) {
        toast.error(e, 3500, {
          defaultHeader: t('views.user_edit.header_error_saved')
        });
      } finally {
        car.loadingSave = false;
      }
    }

    async function saveAction() {
      await updateCar();

      if (route.query.dir === 'back') {
        router.back();
      }
    }

    const saveBtnText = computed(() => {
      return (car.loadingSave)
        ? t('views.user_edit.btn_loading_save')
        : t('views.user_edit.btn_save');
    });

    return {
      saveBtnText,
      carId,
      carCover,
      car,
      loadCar,
      saveAction,
      isTabletAndUp,
    };
  }
});
