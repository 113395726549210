/**
 * Сравнить версии
 * 
 * @param version1 
 * @param version2 
 * @returns Если версия 1 больше, чем версия 2, вернется 1, если меше, то -1, если равны, то 0.
 */
export function compareVersions(version1: string|number, version2: string|number) {
  const splitV1 = String(version1).split('.');
  const splitV2 = String(version2).split('.');

  const minLength = Math.min(splitV1.length, splitV2.length);

  for (let i = 0; i < minLength; ++i) {
    const v1 = parseInt(splitV1[i]);
    const v2 = parseInt(splitV2[i]);

    if (v1 === v2) continue;

    return (v1 > v2) ? 1 : -1;
  }

  // В случае, если разное кол-во разрядов, больше то, у кого разрябов больше
  // Например 1.1.1 > 1.1
  if (splitV1.length !== splitV2.length) {
    return (splitV1.length > splitV2.length) ? 1 : -1;
  }

  return 0;
}