
import { Component, defineComponent, provide, shallowRef, PropType } from 'vue';
import { IonNav } from '@ionic/vue';
import { ViewInfo, ModalNav, ChangeProvidedServicesAction } from './modal';
import SelectPointTypeView from './SelectPointTypeView.vue';
import SelectDoersView from './SelectDoersView.vue';

export default defineComponent({
  inheritAttrs: false,
  components: {
    IonNav,
  },
  props: {
    action: String as PropType<ChangeProvidedServicesAction>
  },
  setup(props) {
    const startedView: ViewInfo = {
      component: (props.action === ChangeProvidedServicesAction.UpdateDoers) ? SelectDoersView : SelectPointTypeView,
      params: {},
    };

    const stackViews: ViewInfo[] = [startedView];
    const currentView = shallowRef<ViewInfo>(startedView);

    const nav: ModalNav = {
      pushView(component: Component, params?: Record<string, any>) {
        const newView: ViewInfo =  { component, params };
        currentView.value = newView;
        stackViews.push(newView);
      },

      backView(defaultView?: ViewInfo) {
        if (stackViews.length <= 1) {
          if (defaultView) {
            stackViews.length = 0;
            stackViews.push(defaultView);
            currentView.value = defaultView;
          } else {
            console.log('Нет представления для того, чтобы вернуться назад');
          }
        } else {
          stackViews.pop();
          currentView.value = stackViews[stackViews.length - 1];
        }
      },

      get backCount() {
        return stackViews.length - 1;
      }
    };

    provide('modalNav', nav);

    return {
      currentView,
    };
  }
});
