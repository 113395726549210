
import { defineComponent, computed, ref, watch } from 'vue';
import {
  getCalendarGrid,
  convertToDateAndCleanTime,
  CalendarDayInfo
} from '@/helpers/datetime';

export default defineComponent({
  emits: ['update:modelValue'],

  props: {
    mounth: {
      type: Number,
      required: true,
    },
    year: {
      type: Number,
      required: true,
    },
    modelValue: {
      type: null,
    },
  },

  setup(props, { emit }) {
    function prepareValue(value: any): Date|null {
      if (!value) return null;

      const dateObject = convertToDateAndCleanTime(value);

      // Optimaze (если дата изначально не попадает в заданный диапазон, то далее
      // проверка на конкретные даты производится не будет и внутренее значение будет == null)
      const { startDate, endDate } = mounthGridInfo.value;
      if (dateObject >= startDate && dateObject <= endDate) {
        return dateObject;
      }

      return null;
    }

    const innerValue = computed<null|Date>({
      get() {
        return lazyValue.value;
      },
      set(value) {
        lazyValue.value = prepareValue(value);
        emit('update:modelValue', innerValue.value);
      },
    });
    const innerValueTimeshtamp = computed(() => innerValue.value?.getTime() || null);
    const mounthGridInfo = computed(() => {
      return getCalendarGrid(props.mounth, props.year);
    });

    function onClickDate(dateInfo: CalendarDayInfo) {
      innerValue.value = dateInfo.dateObject;
    }

    const lazyValue = ref<null|Date>(prepareValue(props.modelValue));
    watch(() => props.modelValue, value => lazyValue.value = prepareValue(value));

    return {
      mounthGridInfo,
      innerValueTimeshtamp,
      onClickDate,
    };
  }
});
