
import { defineComponent, PropType, provide, computed } from 'vue';
import type { ModalServicesContext } from '@/composables/visit';
import { useModalNav } from './modal';
import { ProvideServiceBodyItem } from '@/repositories/Models/CarVisit';
import ServicesProvideChoiceDiscounts from '../provide-form/ServicesProvideChoiceDiscounts.vue';
import { prepareProvidedServicesBodyItems } from '@/helpers/visit';

export default defineComponent({
  inheritAttrs: false,

  components: {
    ServicesProvideChoiceDiscounts,
  },

  props: {
    // HACK: Не передаем значения напрямую,
    // т.к. это вызовет проблемы с обновлением props значений
    useModalServicesContext: {
      type: Function as PropType<() => ModalServicesContext>,
      required: true,
    },

    setProvidedServices: {
      type: Function as PropType<(providedServices: ProvideServiceBodyItem[]) => void>,
      required: false,
    },
    
    userCloseModal: {
      type: Boolean,
      default: true,
    },
  },

  setup(props) {
    const nav = useModalNav();
    const ctx = props.useModalServicesContext();
    provide('visitState', ctx.visitState);

    const providedServices = computed(() => ctx.visitState.body.providedServices || []);

    function apply() {
      props.setProvidedServices?.call(null, [...providedServices.value]);
    }

    const total = computed(() => {
      return prepareProvidedServicesBodyItems(providedServices.value)
        .reduce((s, item) => s + item.totalPrice, 0)
      ;
    });

    return {
      apply,
      total,
      back: () => nav.backView(),
    };
  },
});
