import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { class: "ion-padding-horizontal ion-padding-end" }
const _hoisted_3 = { class: "c-select-provided-services__footer-total" }
const _hoisted_4 = { class: "c-select-provided-services__footer-total-text" }
const _hoisted_5 = { class: "c-select-provided-services__footer-total-price" }
const _hoisted_6 = { class: "core-modal-footer-toolbal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_c_modal_action_header = _resolveComponent("c-modal-action-header")!
  const _component_services_provide_by_category_group = _resolveComponent("services-provide-by-category-group")!
  const _component_c_modal_content = _resolveComponent("c-modal-content")!
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_cc = _resolveComponent("cc")!
  const _component_ion_footer = _resolveComponent("ion-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_c_modal_action_header, {
      tag: "ion-header",
      "show-close": _ctx.userCloseModal
    }, {
      start: _withCtx(() => [
        _createVNode(_component_ion_button, {
          class: "c-modal-header-back-button",
          color: "transparent-text",
          onClick: _ctx.back
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_icon, { name: "chevron-back-outline" })
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("span", {
          innerHTML: _ctx.title || _ctx.$t('components.add_category_modal.view_provided_services_title_default')
        }, null, 8, _hoisted_1)
      ]),
      _: 1
    }, 8, ["show-close"]),
    _createVNode(_component_c_modal_content, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_services_provide_by_category_group, {
            "show-title": false,
            context: {
          categoryId: +_ctx.catId,
          typeId: +_ctx.typeId,
          groupId: !!_ctx.groupId ? +_ctx.groupId : undefined,
          discountAccountId: _ctx.carId,
        },
            provided: _ctx.currentGroupValues,
            "onUpdate:provided": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentGroupValues) = $event)),
            "loading-services": _ctx.loadingServices,
            "onUpdate:loading-services": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.loadingServices) = $event)),
            replaced: _ctx.currentGroupReplaced
          }, null, 8, ["context", "provided", "loading-services", "replaced"])
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_ion_footer, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('components.add_category_modal.view_provided_services_label_total')), 1),
          _createElementVNode("div", _hoisted_5, [
            (_ctx.loadingServices)
              ? (_openBlock(), _createBlock(_component_ion_skeleton_text, {
                  key: 0,
                  animated: "",
                  style: {
          width: '60px',
          height: '1em',
        }
                }))
              : (_openBlock(), _createBlock(_component_cc, {
                  key: 1,
                  price: _ctx.total
                }, null, 8, ["price"]))
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_ion_button, {
            color: "primary",
            expand: "block",
            shape: "round",
            onClick: _ctx.apply,
            disabled: _ctx.loadingServices
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('components.add_category_modal.view_provided_services_btn_next')), 1)
            ]),
            _: 1
          }, 8, ["onClick", "disabled"])
        ])
      ]),
      _: 1
    })
  ], 64))
}