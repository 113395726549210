import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_service_provide_discount_item = _resolveComponent("service-provide-discount-item")!
  const _component_c_content_loading = _resolveComponent("c-content-loading")!

  return (_openBlock(), _createBlock(_component_c_content_loading, { action: _ctx.load }, {
    default: _withCtx(() => [
      _createVNode(_component_service_provide_discount_item, {
        "select-btn-text": "Выбрать для всего заказа",
        "discounts-collection": _ctx.discounts,
        "discount-value": _ctx.visitState.defaultDiscount,
        "onUpdate:discountValue": _ctx.setDefaultDiscount
      }, null, 8, ["discounts-collection", "discount-value", "onUpdate:discountValue"]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.collectionServicesInfo, (item) => {
        return (_openBlock(), _createBlock(_component_service_provide_discount_item, {
          key: `${item.provide.carCategory.id}_${item.provide.service.id}`,
          service: item.service,
          "provide-meta": item.provide.meta,
          "discounts-collection": _ctx.discounts,
          "discount-value": item.provide.discountCampaign,
          "onUpdate:discountValue": (value, discount) => _ctx.setItemDiscount(item, value, discount),
          "discount-percent": item.provide.discountPercent,
          "onUpdate:discount-percent": ($event: any) => ((item.provide.discountPercent) = $event)
        }, null, 8, ["service", "provide-meta", "discounts-collection", "discount-value", "onUpdate:discountValue", "discount-percent", "onUpdate:discount-percent"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["action"]))
}