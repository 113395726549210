
import { VisitBadgeItem } from '@/composables/visit';
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    mainImage: String,
    totalPrice: [Number, String],
    progressText: String,
    formatCarNumber: String,
    badgeList: {
      type: Array as PropType<VisitBadgeItem[]>,
      defqult: () => [],
    }
  }
});
