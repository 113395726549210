
import { defineComponent, ref, watch, computed } from 'vue';

const MASK_REGV = /https:\/\/cid(\d+)\.core12\.ru/;

export default defineComponent({
  emits: ['update:modelValue'],
  props: {
    modelValue: null,
  },
  setup(props, { emit }) {
    const lazyValue = ref(cleanValue(props.modelValue));
    watch(() => props.modelValue, value => lazyValue.value = cleanValue(value));

    const innerValue = computed({
      get() { return lazyValue.value },
      set(value: any) {
        lazyValue.value = cleanValue(value);
        emit('update:modelValue', valueToUrl(lazyValue.value));
      }
    });

    function cleanValue(value: any): string {
      let preparedValue = String(value);

      if (MASK_REGV.test(preparedValue)) {
        preparedValue = preparedValue.replace(MASK_REGV, '$1');
      }

      return preparedValue;
    }

    function valueToUrl(value: string): string {
      let preparedValue = String(value);

      if (/^\d+$/.test(preparedValue)) {
        preparedValue = `https://cid${preparedValue}.core12.ru`;
      }

      return preparedValue;
    }

    return {
      innerValue,
    };
  }
});
