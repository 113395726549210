import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "c-visit-shortinfo" }
const _hoisted_2 = { class: "c-visit-shortinfo__inner-wrap" }
const _hoisted_3 = { class: "c-visit-shortinfo__image-col" }
const _hoisted_4 = { class: "visit-card-badge-container" }
const _hoisted_5 = { class: "c-visit-shortinfo__content" }
const _hoisted_6 = { class: "c-visit-shortinfo__header" }
const _hoisted_7 = { class: "c-visit-shortinfo__car-number" }
const _hoisted_8 = {
  size: "auto",
  class: "c-visit-shortinfo__total"
}
const _hoisted_9 = { class: "c-visit-shortinfo__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_cc = _resolveComponent("cc")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: "c-visit-shortinfo__image",
          style: _normalizeStyle({ backgroundImage: `url('${_ctx.mainImage}')` })
        }, [
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.badgeList, (badge, index) => {
              return (_openBlock(), _createBlock(_component_ion_badge, {
                key: index,
                color: badge.color,
                textContent: _toDisplayString(badge.text)
              }, null, 8, ["color", "textContent"]))
            }), 128))
          ])
        ], 4)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.formatCarNumber), 1),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_cc, { price: _ctx.totalPrice }, null, 8, ["price"])
          ])
        ]),
        _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.progressText), 1)
      ])
    ])
  ]))
}