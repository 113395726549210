
import { defineComponent, provide } from 'vue';
import { useStore } from '@/composables/useApp';
import VisitStoreCheckout from '@/components/store/VisitStoreCheckout.vue';

export default defineComponent({
  components: {
    VisitStoreCheckout,
  },

  setup() {
    const store = useStore();

    provide('visitState', store.visit.newStoreVisitState);

    return {};
  }
});
