
import { defineComponent, ref, computed, watch } from 'vue';
import AppHeaderNewVisit from '@/components/layout/AppHeaderNewVisit.vue';
import { useCameraPreview } from '@/composables/cameraPreview';
import { useNewVisitPhoto } from '@/composables/visit';
import { useToast } from '@/composables/toast';
import { LocationQueryRaw, useRouter } from 'vue-router';
import { base64ToBlob, getExtensionFromMimiType } from '@/helpers/image';
import { useApp } from '@/composables/useApp';
import { awaitLoading } from '@/ext/loading';
import { get } from 'lodash';
import { useMetrica } from '@/composables/useMetrica';
import { useI18n } from 'vue-i18n';
import { onIonViewDidEnter, onIonViewDidLeave, isPlatform } from '@ionic/vue';
import { windowSize } from '@/helpers/adaptive';

export default defineComponent({
  components: {
    AppHeaderNewVisit,
  },

  setup() {
    const toast = useToast();
    const router = useRouter();
    const { repositories, store } = useApp();
    const { saveNewVisitPhotoToDb } = useNewVisitPhoto({ autoloadCache: false });
    const { emitEvent } = useMetrica();
    const { t } = useI18n();

    const useNumberRecognition = store.config.useNumberRecognition;
    const containerSizeRef = ref<HTMLElement|null>(null);

    const {
      startPreview,
      stopPreview,
      capture,
      isInited,
    } = useCameraPreview({
      autostart: false,
      autostop: true,
      parentId: 'app',
      className: 'c-visit-new-preview-video',
    });

    onIonViewDidEnter(startPreview);
    onIonViewDidLeave(stopPreview);

    if (isPlatform('capacitor') && isPlatform('android')) {
      watch(windowSize, async () => {
        if (false === isInited.value) return;

        await stopPreview();
        await startPreview();
      });
    }

    async function createAndSavePhoto() {
      if (false === isInited.value) {
        toast.error(t('views.visit_new_photo.error_camera_no_init'));
        return null;
      }

      const imageBase64 = 'data:image/png;base64,' + await capture({
        height: 1920,
        width: 1080,
        quality: 75,
      });
      await saveNewVisitPhotoToDb(imageBase64);

      return imageBase64;
    }

    function nextStep(query: LocationQueryRaw = {}) {
      router.push({
        name: 'visit-new-car-number',
        query,
      });
    }

    async function recognizePhotoAction() {
      emitEvent('visit/new/photo/recognize');

      const imageBase64 = await createAndSavePhoto();

      try {
        if (!imageBase64) {
          throw new Error(t('views.visit_new_photo.error_create_photo'));
        }

        let imageBlob = base64ToBlob(imageBase64);

        if (!imageBlob) {
          throw new Error('Failed to convert image, contact your administrator');
        }

        const extensionFile = getExtensionFromMimiType(imageBlob.type);
        const formData = new FormData();
        formData.append('file', imageBlob, `photo.${extensionFile}`);

        await awaitLoading(async () => {
          const res = await repositories.car.recognizeNumber(formData);
          const carNumber = get(res.data, 'number.0') as string|undefined;

          if (carNumber) {
            emitEvent('car/number/recognize/success');
            nextStep({ number: carNumber });
          } else {
            emitEvent('car/number/recognize/error');
            toast.warning(t('views.visit_new_photo.error_recognize_photo'));
            nextStep();
          }
        }, {
          message: t('views.visit_new_photo.wait_recognize_number'),
          showToastError: false,
        });
      } catch (e) {
        toast.error(e);
      }
    }

    async function skipAction() {
      if (useNumberRecognition.value) {
        emitEvent('visit/new/photo/recognize/skip');
        await createAndSavePhoto();
      } else {
        emitEvent('visit/new/photo/skip');
        await saveNewVisitPhotoToDb(null);
      }

      // Даже если при создании фото возникла ошибка, продолжим оформление
      nextStep();
    }

    /** @click Сделать фото автомобиля */
    async function createPhotoAction() {
      emitEvent('visit/new/photo/create');
      await createAndSavePhoto();
      nextStep();
    }

    const skipBtnText = computed(() => {
      return (useNumberRecognition.value)
        ? t('views.visit_new_photo.btn_skip_no_recognition')
        : t('views.visit_new_photo.btn_skip_no_photo');
    });

    return {
      containerSizeRef,
      recognizePhotoAction,
      skipAction,
      useNumberRecognition,
      createPhotoAction,
      skipBtnText,
      isInited,
    };
  },
});
