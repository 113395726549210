
import { defineComponent, ref, inject } from 'vue'
import { useStore } from '@/composables/useApp'
import { ProvidedServicesInformation } from '@/store/ServiceStore'
import ServiceProvideDiscountItem from './ServiceProvideDiscountItem.vue'
import { useDiscounts } from '@/composables/useDiscounts'
import { Discount } from '@/repositories/Models/Discount'
import { clone, isEqual, pick, uniqWith } from 'lodash'
import { NewVisitSatate } from '@/store/NewVisitState';
import { useMetrica } from '@/composables/useMetrica'

export default defineComponent({
  components: {
    ServiceProvideDiscountItem,
  },
  
  props: {
    stateInjectKey: {
      type: String,
      required: true,
    }
  },

  setup(props) {
    const visitAction = inject<string>('visitAction', 'new');
    const visitState = inject<NewVisitSatate>(props.stateInjectKey, null as unknown as NewVisitSatate);
    if (!visitState) {
      throw new Error('Не найдено состояние с данными заказа');
    }

    const store = useStore();
    const { discounts, loadDiscounts } = useDiscounts();
    const { emitEvent } = useMetrica();

    const collectionServicesInfo = ref<ProvidedServicesInformation[]>([]);
    async function loadServicesByProvidedItems() {
      collectionServicesInfo.value = await store.service.getProvidedServicesInformation(
        visitState.body.providedServices || [],
        visitState.body.group?.id,
        visitState.body.car?.id,
        false
      );
    }

    function load() {
      return Promise.all([
        loadServicesByProvidedItems(),
        loadDiscounts(),
      ]);
    }

    function setDefaultDiscount(discountValue: Pick<Discount, "id"|"type">|null, discount: Discount|null) {
      const discountAct = discount ? 'set-default' : 'remove-default';
      emitEvent(`visit/${visitAction}/discounts/${discountAct}`, discount || undefined);
      
      const prepareDiscount = discountValue ? { ...discountValue } : null;
      const discountPercent = discount ? discount.percent : undefined;
      
      visitState.defaultDiscount = prepareDiscount;

      // При выборе скидки по умолчанию, установим для всех
      collectionServicesInfo.value.forEach(({ provide }) => {
        provide.discountCampaign = prepareDiscount || undefined;
        provide.discountPercent = discountPercent;
      });
    }

    function setItemDiscount(
      item: ProvidedServicesInformation,
      discountValue: Pick<Discount, "id"|"type">|null,
      discount: Discount|null
    ) {
      const discountAct = discountValue ? 'set-service' : 'remove-service';
      emitEvent(`visit/${visitAction}/discounts/${discountAct}`, {
        service: pick(item.service, ['id', 'name', 'isProduct', 'fluid', 'repeatable']),
        provide: pick(item.provide, ['qty', 'singleItemPrice']),
        discount: pick(discount, ['id', 'name', 'percent', 'type', 'impactsDoerComission', 'impactsManagerComission' ]),
      });

      item.provide.discountCampaign = discountValue || undefined;

      // При выборе скидки для услуги правильно установим значение по-умолчанию
      const selectedDiscountCampains = collectionServicesInfo.value.map(item => item.provide.discountCampaign);
      if (uniqWith(selectedDiscountCampains, isEqual).length > 1) {
        visitState.defaultDiscount = null;
      } else {
        visitState.defaultDiscount = clone(discountValue);
      }
    }

    return {
      collectionServicesInfo,
      discounts,
      load,
      visitState,
      setDefaultDiscount,
      setItemDiscount,
    };
  },
});
