import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  slot: "fixed",
  class: "visits-fixed-toolbar"
}
const _hoisted_2 = { class: "visits-fixed-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_ion_segment_button = _resolveComponent("ion-segment-button")!
  const _component_ion_segment = _resolveComponent("ion-segment")!
  const _component_c_transparent_button = _resolveComponent("c-transparent-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_c_divider = _resolveComponent("c-divider")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_c_infinite_calendar = _resolveComponent("c-infinite-calendar")!
  const _component_visits_preentry_shedule_box = _resolveComponent("visits-preentry-shedule-box")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { class: "visit-preenty-header" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, { class: "header-titlebar" }, {
            default: _withCtx(() => [
              _withDirectives(_createVNode(_component_ion_chip, {
                outline: "",
                color: "primary",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.currentTab = 'selectDate'))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.customDateFormat), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_icon, {
                    icon: "close-outline",
                    onClick: _withModifiers(_ctx.clearCustomDate, ["stop"])
                  }, null, 8, ["onClick"])
                ]),
                _: 1
              }, 512), [
                [_vShow, _ctx.customDate]
              ]),
              _withDirectives(_createVNode(_component_ion_segment, {
                class: "core-segment",
                modelValue: _ctx.currentTab,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentTab) = $event))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_segment_button, {
                    value: "currentDate",
                    layout: "icon-hide"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('views.visits_preentry.tab_current_day')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_segment_button, {
                    value: "nextDate",
                    layout: "icon-hide"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('views.visits_preentry.tab_next_day')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_segment_button, {
                    value: "selectDate",
                    layout: "icon-hide"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('views.visits_preentry.tab_select_date')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["modelValue"]), [
                [_vShow, !_ctx.customDate]
              ]),
              _createVNode(_component_ion_buttons, { slot: "end" }, {
                default: _withCtx(() => [
                  _createVNode(_component_c_transparent_button, { onClick: _ctx.toVisits }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        name: "close-outline",
                        class: "icon-medium"
                      })
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_c_divider, { class: "my-0" })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { "scroll-y": false }, {
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_KeepAlive, null, [
            (_ctx.isSelectDate)
              ? (_openBlock(), _createBlock(_component_c_infinite_calendar, {
                  key: 0,
                  "onUpdate:modelValue": _ctx.setCustomDate,
                  modelValue: _ctx.customDate
                }, null, 8, ["onUpdate:modelValue", "modelValue"]))
              : (_openBlock(), _createBlock(_component_visits_preentry_shedule_box, {
                  key: 1,
                  date: _ctx.viewSheduleDate,
                  onClickVisit: _ctx.toVisitSingle,
                  "visits-auto-sync": true
                }, null, 8, ["date", "onClickVisit"]))
          ], 1024)),
          _createElementVNode("div", _hoisted_1, [
            _withDirectives(_createElementVNode("div", _hoisted_2, [
              _createVNode(_component_ion_button, {
                color: "primary",
                shape: "round",
                onClick: _ctx.newVisitPreentry
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    name: "add-outline",
                    class: "mr-1"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('views.visits_preentry.btn_add_preentry')), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ], 512), [
              [_vShow, _ctx.showAddButton]
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}