
import { defineComponent, computed, ref, inject, DeepReadonly } from 'vue'
import ServicesProvidedViewCard from '@/components/service/provide-view/ServicesProvidedViewCard.vue'
import { ServiceCollectionItem } from '@/repositories/Models/Service'
import { NewVisitSatate } from '@/store/NewVisitState'
import { isEmpty, map, keyBy, Dictionary } from 'lodash'
import { useStore } from '@/composables/useApp'

export default defineComponent({
  components: {
    ServicesProvidedViewCard,
  },
  props: {
    total: {
      type: Number,
      required: true,
    },
    discountPercent: {
      type: Number,
      required: false,
    }
  },
  setup() {
    const store = useStore();

    const allServicesIndex = ref<DeepReadonly<Dictionary<ServiceCollectionItem>>>({});
    async function loadServices() {
      allServicesIndex.value = keyBy(await store.service.getAll(), 'id');
    }

    const visitState = inject<NewVisitSatate|null>('visitState', null);
    if (!visitState) throw new Error('Mandatory state of a new store visit is not passed');

    const hasProvidedServices = computed(() => !isEmpty(visitState.body.providedServices));

    const providedServices = computed(() => {
      if (isEmpty(allServicesIndex.value) || isEmpty(visitState.body.providedServices)) {
        return [];
      }

      return map(visitState.body.providedServices, item => {
        return {
          ...item,
          service: allServicesIndex.value[`${item.service.id}`] || null,
        };
      })
    });

    return {
      providedServices,
      loadServices,
      hasProvidedServices,
    };
  }
});
